import {
  RecruitingProcessActionInputParameterTypeEnum,
  RecruitingProcessActionSourceInterface,
  RecruitingProcessInterface
} from '../../../../../process-generator/process-entities.interface';
import {applyForm_itSpecialist_idFixture, recruitingProcess_itSpecialist_idFixture} from '../../fixture-ids.fixture';

export let recruitingProcessesFixture: Array<RecruitingProcessInterface> = [
  {
    id: recruitingProcess_itSpecialist_idFixture,
    applyFormId: applyForm_itSpecialist_idFixture,
    name: 'IT-Fachkraft',
  },
  {
    id: '6da7dcff-e141-42bc-9c53-fb94897c7b00',
    applyFormId: 'ce93664b-c0a4-4e51-829e-aaecab173a31',
    name: 'Ärzt*in'
  },
  {
    id: '5e384b44-6d16-4123-afb3-73063cd7cf13',
    applyFormId: '60b3603f-2d9e-4f7a-9d88-41a188b5498c',
    name: 'Erzieher*in'
  },
  {
    id: '048b8d0a-741a-411e-be04-db563c8e5c38',
    applyFormId: '175d3d00-3986-4d3c-a795-e869255138e5',
    name: 'Krankenpfleger*in'
  },
];


export let recruitingProcessesActionsFixture: Array<RecruitingProcessActionSourceInterface> = [
  {
    id: '56c6bd2a-972e-4cb7-92bd-d119abfdd6ee',
    name: 'Mail',
    description: 'Mail connector langer Beschreibungstext als Platzhalter',
    iconUrl: '/assets/process-generator/mail.png',
    inputActionParameters: [
      {
        key: 'to',
        name: 'An',
        helpText: 'Empfänger der E-Mail',
        type: RecruitingProcessActionInputParameterTypeEnum.string,
        isRequired: true,
      },
      {
        key: 'cc',
        name: 'CC',
        helpText: 'Kopieempfänger der E-Mail',
        type: RecruitingProcessActionInputParameterTypeEnum.string,
        isRequired: false,
      },
      {
        key: 'subject',
        name: 'Betreff',
        helpText: 'Betreff der E-Mail',
        type: RecruitingProcessActionInputParameterTypeEnum.string,
        isRequired: true,
      },
      {
        key: 'message',
        name: 'Nachricht',
        helpText: 'Inhalt der E-Mail',
        type: RecruitingProcessActionInputParameterTypeEnum.text,
        isRequired: true,
      },
    ],
    outputActionParameters: [
      {
        key: 'to',
        name: 'An',
        sample: 'batz@example.com',
      },
      {
        key: 'subject',
        name: 'Betreff',
        sample: 'Ihre Bewerbung',
      },
      {
        key: 'sentDate',
        name: 'Gesendet',
        sample: '03.08.2022 13:38'
      },
    ],
  },
  {
    id: '5116361e-b256-4fef-90e6-61841fb3696e',
    name: 'Löschvermerk',
    description: 'Zu diese Bewerbung erhobene Daten werden nach Ablauf des Löschzeitraumes gelöscht. Bis dahin verbleiben sie im System.',
    iconUrl: '/assets/process-generator/recycle-bin.png',
    inputActionParameters: [
      {
        key: 'purgePeriod',
        name: 'Löschzeitraum',
        helpText: 'Mit Ablauf des Löschzeitraums wird diese Bewerbung gelöscht.',
        type: RecruitingProcessActionInputParameterTypeEnum.select,
        isRequired: true,
      },
      {
        key: 'reason',
        name: 'Grund',
        helpText: 'Grund für die manuelle Löschung.',
        type: RecruitingProcessActionInputParameterTypeEnum.text,
        isRequired: true,
      },
    ],
    outputActionParameters: [
      {
        key: 'purgeDate',
        name: 'Löschdatum',
        sample: '03.08.2022 13:38'
      },
    ],
  },
  {
    id: '9c2d273d-f6e1-430a-8462-e249225b833c',
    name: 'Webhook',
    description: 'Webhook langer Beschreibungstext als Platzhalter',
    iconUrl: '/assets/process-generator/webhook.png',
    inputActionParameters: [
      {
        key: 'url',
        name: 'Zieladresse',
        helpText: 'https://api.example.com/v1/posts',
        type: RecruitingProcessActionInputParameterTypeEnum.string,
        isRequired: true,
      },
      {
        key: 'method',
        name: 'HTTP-Methode',
        helpText: 'POST',
        type: RecruitingProcessActionInputParameterTypeEnum.select,
        // @todo selection box für input felder definieren
        isRequired: true,
      }
    ],
    outputActionParameters: [
      {
        key: 'statusCode',
        name: 'Status Code',
        sample: '200',
      },
      {
        key: 'statusPhrase',
        name: 'Status Phrase',
        sample: 'OK',
      },
      {
        key: 'response',
        name: 'Antwort',
        sample: '{json: "Beispiel"}'
      },
    ],
  },
  {
    id: '3a2477a3-f246-4048-ac12-18b9bef4a1f5',
    name: 'Bedingung',
    description: 'Bedingung langer Beschreibungstext als Platzhalter',
    iconUrl: '/assets/process-generator/condition.png',
    inputActionParameters: [
      {
        key: 'condition',
        name: 'Bedingung',
        helpText: 'A größer als B',
        type: RecruitingProcessActionInputParameterTypeEnum.boolean,
        isRequired: true,
      }
    ],
    outputActionParameters: [],
  },
  {
    id: '5b2c2208-6af0-4749-8253-a831fb9abb66',
    name: 'Kalendereintrag erstellen',
    description: 'Kalendereintrag erstellen langer Beschreibungstext als Platzhalter',
    iconUrl: '/assets/process-generator/calender.png',
    inputActionParameters: [
      {
        key: 'datetime',
        name: 'Datum und Uhrzeit',
        helpText: 'Datum und Uhrzeit des Termins festlegen',
        type: RecruitingProcessActionInputParameterTypeEnum.datetime,
        isRequired: true,
      },
      {
        key: 'description',
        name: 'Beschreibung',
        helpText: 'Beschreibung des Termins festlegen',
        type: RecruitingProcessActionInputParameterTypeEnum.text,
        isRequired: false,
      }
    ],
    outputActionParameters: [],
  },
  {
    id: '62c1bcba-486c-44e6-b808-1e2bc6cdcd58',
    name: 'MS Teams Nachricht senden',
    description: 'MS Teams Nachricht senden erstellen langer Beschreibungstext als Platzhalter',
    iconUrl: '/assets/process-generator/ms-teams.png',
    inputActionParameters: [
      {
        key: 'channel',
        name: 'Kanal',
        helpText: 'Zielkanal',
        type: RecruitingProcessActionInputParameterTypeEnum.select,
        isRequired: true,
      },
      {
        key: 'message',
        name: 'Nachricht',
        helpText: 'Nachricht',
        type: RecruitingProcessActionInputParameterTypeEnum.text,
        isRequired: true,
      },
    ],
    outputActionParameters: [],
  },
  {
    id: 'a26b856b-31ce-435e-a90e-b6e4fc220ee4',
    name: 'Facebook Nachricht senden',
    description: 'Facebook Nachricht senden erstellen langer Beschreibungstext als Platzhalter',
    iconUrl: '/assets/process-generator/facebook.webp',
    inputActionParameters: [
      {
        key: 'to',
        name: 'An',
        helpText: 'An',
        type: RecruitingProcessActionInputParameterTypeEnum.select,
        isRequired: true,
      },
      {
        key: 'message',
        name: 'Nachricht',
        helpText: 'Nachricht',
        type: RecruitingProcessActionInputParameterTypeEnum.text,
        isRequired: true,
      },
    ],
    outputActionParameters: [],
  },
  {
    id: 'f9125405-5188-4be8-89b5-9e37a561e549',
    name: 'Bewerberpool Anfrage',
    description: 'Anfrage an den Bewerber ob er/sie in den Bewerberpool aufgenommen werden soll.',
    iconUrl: '/assets/process-generator/mail.png',
    inputActionParameters: [
      {
        key: 'to',
        name: 'An',
        helpText: 'An',
        type: RecruitingProcessActionInputParameterTypeEnum.email,
        isRequired: true,
      },
      {
        key: 'message',
        name: 'Nachricht',
        helpText: 'Nachricht',
        type: RecruitingProcessActionInputParameterTypeEnum.text,
        isRequired: true,
      },
    ],
    outputActionParameters: [],
  },
  {
    // @todo https://jobcluster.atlassian.net/browse/BMS-102
    id: '563a02f2-fd8e-44f1-8264-0da40b2f05ba',
    name: 'Zeitverzögerung',
    description: 'Alle nachfolgenden Aktionen werden um die hier angegebene Zeit verzögert ausgeführt.',
    iconUrl: '/assets/process-generator/time-delay.png',
    inputActionParameters: [
      {
        key: 'delay',
        name: 'Anzahl',
        helpText: 'Anzahl der Zeiteinheiten um die nachfolgende Aktionen verzögert werden.',
        type: RecruitingProcessActionInputParameterTypeEnum.integer,
        isRequired: true,
      },
      {
        key: 'timeUnit',
        name: 'Zeiteinheit',
        helpText: 'Zeiteinheit um die nachfolgende Aktionen verzögert werden.',
        type: RecruitingProcessActionInputParameterTypeEnum.select,
        isRequired: true,
      },
    ],
    outputActionParameters: [],
  },
];
