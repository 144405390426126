import {KtdGridLayoutItem} from '@katoid/angular-grid-layout/lib/grid.definitions';


export interface WidgetInterface extends KtdGridLayoutItem {
  id: string;
  type: WidgetTypeEnum;
  name: string;
}


export enum WidgetTypeEnum {
  GAUGE = 'gauge',
  NUMBER = 'number',
  GRAPH = 'graph',
}
