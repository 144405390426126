import {JobAdInterface} from '../../../../../job-ad/job-ad-list/job-ad-list.component';
import {recruitingProcess_itSpecialist_idFixture} from '../../fixture-ids.fixture';
import {JobAdsTypeDefinitionEnum} from '../job-ads-type-definition/job-ads-type-definition.fixture';


export let jobAdsFixture: JobAdInterface[] = [
  {
    id: 'c43aaeaa-51f5-419b-89f6-ecb9544d4bb2',
    jobTitle: 'SAP Integration Engineer (m/w/d)',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Göttingen'},
    ],
    jobCompanyName: 'Würth IT GmbH',
    jobCompanyStreet: 'Hiroshimaplatz 1-4',
    jobCompanyZipCode: '37028',
    jobCompanyCity: 'Göttingen',
    metaDataDateTime: '2023-01-16T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Vollzeit},
      {typeId: JobAdsTypeDefinitionEnum.Teilzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [],
    recruitingProcessId: recruitingProcess_itSpecialist_idFixture
  },
  {
    id: '9346fa3c-9abc-4918-8f26-c8fecb8240f4',
    jobTitle: 'Account Manager / IT Beratung / Consulting (m/w/d)',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Dresden'},
    ],
    jobCompanyName: 'Siemens AG',
    jobCompanyStreet: 'Siemensstraße 22',
    jobCompanyZipCode: '80333',
    jobCompanyCity: 'München',
    metaDataDateTime: '2022-03-04T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Vollzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 4},
      {statusId: '9d79078b-5251-4671-8cb2-a9ca40555593', statusCount: 6},
      {statusId: '94e57986-6e75-4906-870c-7d0ce1a02a3b', statusCount: 7},
    ],
    recruitingProcessId: recruitingProcess_itSpecialist_idFixture
  },
  {
    id: '78f7d472-210f-4d39-bf52-71f3eba03944',
    jobTitle: 'Fachinformatiker (m/w/d) für Systemintegration zur IT-Administration an den landkreiseigenen Schulen',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '97421', jobLocationCity: 'Schweinfurt'},
    ],
    jobCompanyName: 'Landratsamt Schweinfurt',
    jobCompanyStreet: 'Datenweg 99',
    jobCompanyZipCode: '97421',
    jobCompanyCity: 'Schweinfurt',
    metaDataDateTime: '2022-01-07T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Teilzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 4},
      {statusId: '9d79078b-5251-4671-8cb2-a9ca40555593', statusCount: 6},
      {statusId: 'd2cd744e-f228-4d3f-92b4-48ac319ba06b', statusCount: 7},
    ],
    recruitingProcessId: recruitingProcess_itSpecialist_idFixture
  },
  {
    id: 'e1f46bcb-3602-4fcd-9813-ea82f9f051db',
    jobTitle: 'Klinikärztin-/ arzt (m/w/d)',
    jobLocations: [
      {jobLocationStreet: 'Spitalstraße 22', jobLocationZipCode: '97421', jobLocationCity: 'Schweinfurt'},
      {jobLocationStreet: 'Domstraße 19', jobLocationZipCode: '97080', jobLocationCity: 'Würzburg'},
      {jobLocationStreet: 'Schweinfurter Straße 1', jobLocationZipCode: '97081', jobLocationCity: 'Würzburg'},
    ],
    jobCompanyName: 'Wirbelsäulenzentrum Würzburg',
    jobCompanyStreet: 'Schweinfurter Str. 1',
    jobCompanyZipCode: '97080',
    jobCompanyCity: 'Würzburg',
    metaDataDateTime: '2022-06-03T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Vollzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 11},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 3},
    ],
    recruitingProcessId: '6da7dcff-e141-42bc-9c53-fb94897c7b00'
  },
  {
    id: '61903694-7dc2-452d-8abb-cd6c2e04cf11',
    jobTitle: 'Arzt / Ärztin für den die Beratung im Bereich Lipödem',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Düsseldorf'},
    ],
    jobCompanyName: 'Lipocura Düsseldorf GmbH',
    jobCompanyStreet: 'Darmstädter Landstr. 125',
    jobCompanyZipCode: '60598',
    jobCompanyCity: 'Düsseldorf',
    metaDataDateTime: '2022-08-10T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Teilzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 5},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 1},
    ],
    recruitingProcessId: '6da7dcff-e141-42bc-9c53-fb94897c7b00'
  },
  {
    id: '49553fc2-2660-49cb-a5d1-60534d2cb2df',
    jobTitle: 'Facharzt Allgemeinmedizin (m/w/d)',
    jobLocations: [
      {jobLocationStreet: 'Westenmauer 10', jobLocationZipCode: '97070 ', jobLocationCity: 'Landkreis Würzburg'},
    ],
    jobCompanyName: 'Praxis Landkreis Würzburg',
    jobCompanyStreet: '',
    jobCompanyZipCode: '',
    jobCompanyCity: '',
    metaDataDateTime: '2022-04-02T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Vollzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
    ],
    recruitingProcessId: '6da7dcff-e141-42bc-9c53-fb94897c7b00'
  },
  {
    id: '3a88576a-ef61-42ff-b9bf-7ff6f6b30ffd',
    jobTitle: 'Erzieher (m/w/d) für die stationäre Kinder- und Jugendhilfe',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Eichenzell'},
    ],
    jobCompanyName: 'pluss Personalmanagement GmbH -Bildung und Soziales-',
    jobCompanyStreet: 'Löwenstraße 22',
    jobCompanyZipCode: '55886',
    jobCompanyCity: 'Bad Löwenau',
    metaDataDateTime: '2022-06-07T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Ausbildung},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 4},
      {statusId: '9d79078b-5251-4671-8cb2-a9ca40555593', statusCount: 6},
      {statusId: '94e57986-6e75-4906-870c-7d0ce1a02a3b', statusCount: 7},
    ],
    recruitingProcessId: '5e384b44-6d16-4123-afb3-73063cd7cf13'
  },
  {
    id: '74b31ff5-8947-485e-8c30-abe94d30936e',
    jobTitle: 'Sozialpädagogin (m/w/d) / Erzieherin (m/w/d) in unserer heilpädagogischen Wohngemeinschaft',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Weilheim i. Oberbayern'},
    ],
    jobCompanyName: 'SOS Kinderdorf',
    jobCompanyStreet: 'Westenmauer 10',
    jobCompanyZipCode: '59227',
    jobCompanyCity: 'Weilheim i. Oberbayern',
    metaDataDateTime: '2022-06-25T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Vollzeit},
      {typeId: JobAdsTypeDefinitionEnum.Teilzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 4},
      {statusId: '9d79078b-5251-4671-8cb2-a9ca40555593', statusCount: 6},
      {statusId: '94e57986-6e75-4906-870c-7d0ce1a02a3b', statusCount: 7},
    ],
    recruitingProcessId: '5e384b44-6d16-4123-afb3-73063cd7cf13'
  },
  {
    id: 'e7c7218c-c675-4f9b-aa86-2debd3a7bae7',
    jobTitle: 'Inklusionsbegleiter (m/w/d)',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'München'},
    ],
    jobCompanyName: 'Lebenshilfe Werkstatt München',
    jobCompanyStreet: 'Westenmauer 10',
    jobCompanyZipCode: '80333',
    jobCompanyCity: 'München',
    metaDataDateTime: '2022-06-25T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Vollzeit},
      {typeId: JobAdsTypeDefinitionEnum.Teilzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 4},
      {statusId: '9d79078b-5251-4671-8cb2-a9ca40555593', statusCount: 6},
      {statusId: '94e57986-6e75-4906-870c-7d0ce1a02a3b', statusCount: 7},
    ],
    recruitingProcessId: '5e384b44-6d16-4123-afb3-73063cd7cf13'
  },
  {
    id: '2a554d1a-aadf-4906-b26a-1ad4dfcd5085',
    jobTitle: 'examinierter Altenpfleger (m/w/d) / examinierter Gesundheits-und Krankenpfleger (m/w/d) / examinierte Pflegefachkraft (m/w/d)',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Bielefeld'},
    ],
    jobCompanyName: 'Evangelisches Klinikum Niederrhein gGmbH',
    jobCompanyStreet: 'Albert-Schweitzer-Campus 1',
    jobCompanyZipCode: '48149',
    jobCompanyCity: 'Bielefeld',
    metaDataDateTime: '2022-06-25T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Teilzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 4},
      {statusId: '9d79078b-5251-4671-8cb2-a9ca40555593', statusCount: 6},
      {statusId: '94e57986-6e75-4906-870c-7d0ce1a02a3b', statusCount: 7},
    ],
    recruitingProcessId: '048b8d0a-741a-411e-be04-db563c8e5c38'
  },
  {
    id: 'fe449b09-5fc8-4939-93df-a58960d1ff6a',
    jobTitle: 'Medizinische Fachangestellte / Gesundheits- und Krankenpfleger oder Altenpfleger / Notfallsanitäter als Quereinsteiger (m/w/d) für die Dialyse',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Bielefeld'},
    ],
    jobCompanyName: 'Nephrocare Bielefeld',
    jobCompanyStreet: 'Albert-Schweitzer-Campus 1',
    jobCompanyZipCode: '48149',
    jobCompanyCity: 'Bielefeld',
    metaDataDateTime: '2022-06-25T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Vollzeit},
      {typeId: JobAdsTypeDefinitionEnum.Teilzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 4},
      {statusId: '9d79078b-5251-4671-8cb2-a9ca40555593', statusCount: 6},
      {statusId: '94e57986-6e75-4906-870c-7d0ce1a02a3b', statusCount: 7},
    ],
    recruitingProcessId: '048b8d0a-741a-411e-be04-db563c8e5c38'
  },
  {
    id: '8ec3c820-fd80-435e-b90a-9b5d18fbda5f',
    jobTitle: 'Gesundheits- und Krankenpfleger (gn*) oder Altenpflegefachkräfte (gn*) für die Dermatologie',
    jobLocations: [
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Münster'},
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Düsseldorf'},
      {jobLocationStreet: 'Waldweg 99', jobLocationZipCode: '01111', jobLocationCity: 'Osnabrück'},
    ],
    jobCompanyName: 'Universitätsklinikum Münster',
    jobCompanyStreet: 'Albert-Schweitzer-Campus 1',
    jobCompanyZipCode: '48149',
    jobCompanyCity: 'Münster',
    metaDataDateTime: '2022-06-25T06:03:11+00:00',
    metaDataTypes: [
      {typeId: JobAdsTypeDefinitionEnum.Vollzeit},
      {typeId: JobAdsTypeDefinitionEnum.Teilzeit},
    ],
    metaDataStatistics: [],
    metaDataStatus: [
      {statusId: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5', statusCount: 1},
      {statusId: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4', statusCount: 2},
      {statusId: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5', statusCount: 4},
      {statusId: '9d79078b-5251-4671-8cb2-a9ca40555593', statusCount: 6},
      {statusId: '94e57986-6e75-4906-870c-7d0ce1a02a3b', statusCount: 7},
    ],
    recruitingProcessId: '048b8d0a-741a-411e-be04-db563c8e5c38'
  },
];
