<button [matMenuTriggerFor]="userMenu"
        mat-icon-button
>
  <mat-icon>account_circle</mat-icon>
  <mat-icon>keyboard_arrow_down</mat-icon>
</button>
<mat-menu #userMenu
          class="bms-custom-user-menu"
>
  <div
      class="menu-head-user"
      fxLayout="row"
      fxLayoutAlign="start center"
  >

    <jcd-avatar-photo
        [firstName]=profileData?.firstName
        [lastName]=profileData?.lastName
        [photoUrl]=profileData?.avatarLink
    ></jcd-avatar-photo>


    <div fxLayout="column"
         fxLayoutAlign="start start"
    >
      <div>{{profileData?.firstName}} {{profileData?.lastName}}</div>
      <div>{{profileData?.companyName}}</div>
    </div>
  </div>
  <button (click)="userShowProfile(profileData?.userId)"
          class="menu-item"
          mat-menu-item
  >
    <mat-icon>account_box</mat-icon>
    Profil
  </button>
  <button (click)="userLogOff()"
          class="menu-item"
          mat-menu-item
  >
    <mat-icon>exit_to_app</mat-icon>
    Abmelden
  </button>
</mat-menu>
