import {Component, HostListener, OnInit} from '@angular/core';
import {JcdApiSuccessResponse} from 'jcd-shared-components';
import {UserNotificationGetAllResponseInterface} from '../../api/api-response.interface';
import {ProfileApiService} from '../../api/profile-api.service';


// @toDo eventuell durch User-Object ersetzen
export interface NotificationInterface {
  notificationId: string;
  avatarLink: string;
  firstName: string;
  lastName: string;
  notificationSubject: string;
  appliedAs: string;
  appliedCompany: string;
  notificationDateTime: string;
  notificationIsRead: boolean;
}

@Component({
  selector: 'bms-menu-notifications',
  templateUrl: './menu-notifications.component.html',
  styleUrls: ['./menu-notifications.component.scss'],
})
export class MenuNotificationsComponent implements OnInit {
  counterNotifications = 0;
  notificationData: NotificationInterface[] = [];
  notificationDisplayData: NotificationInterface[] = [];

  constructor(
    private userApiService: ProfileApiService
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.notificationDisplayData = this.notificationData.slice(0,Math.round(window.innerHeight / 220))
  }

  openNotificationDetails(notificationID: string): void {
    // @todo show explicit email
    alert('todo notificationID: ' + notificationID);
  }

  openAllNotificationDetails(): void {
    // @todo add process add modal
    alert('todo');
  }




   getData(): void {
    const userNotificationGetAllSuccessCallback = (apiResponse: JcdApiSuccessResponse<UserNotificationGetAllResponseInterface>): void => {
      this.counterNotifications = apiResponse.payload.length;
      this.notificationData = apiResponse.payload;
      this.notificationDisplayData = this.notificationData.slice(0, Math.round(window.innerHeight / 220));
    };

     this.userApiService.userNotificationGetAll(
       {filter: 'unread', limit: 10},
       userNotificationGetAllSuccessCallback,
     );
   }


  public getDateDisplay(dateToCheck: string | undefined): string {
    if (dateToCheck !== undefined) {
      if (new Date(dateToCheck).isToday()) {
        return 'Heute';
      }
      return new Date(dateToCheck).toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric'});
    }
    return '';
  }
}
