import {UserPermissionInterface} from '../../../../../user-management/entities';
import {
  group_admin_idFixture,
  group_recruiterIt_idFixture,
  group_recruiterService_idFixture,
  organisationLevel_halle_idFixture,
  organisationLevel_munich_idFixture,
  organisationLevel_stuttgart_idFixture,
  organisationLevel_stuttgartIt_idFixture,
  users_baumbachImmanuel_idFixture,
  users_beerAntonetta_idFixture
} from '../../fixture-ids.fixture';


export let organisationUsersPermissionFixture: Array<UserPermissionInterface> = [
  {
    groupId: group_admin_idFixture,
    organisationLevelId: organisationLevel_munich_idFixture,
    userId: users_baumbachImmanuel_idFixture,
  },
  {
    groupId: group_admin_idFixture,
    organisationLevelId: organisationLevel_stuttgart_idFixture,
    userId: users_baumbachImmanuel_idFixture,
  },
  {
    groupId: group_recruiterIt_idFixture,
    organisationLevelId: organisationLevel_stuttgartIt_idFixture,
    userId: users_baumbachImmanuel_idFixture,
  },
  {
    groupId: group_recruiterIt_idFixture,
    organisationLevelId: organisationLevel_halle_idFixture,
    userId: users_beerAntonetta_idFixture,
  },
  {
    groupId: group_recruiterService_idFixture,
    organisationLevelId: organisationLevel_halle_idFixture,
    userId: users_beerAntonetta_idFixture,
  },
];
