import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {filter, map} from 'rxjs';
import {RouteData, RouteParentMenuNameEnum} from '../../../app-routing.module';

@UntilDestroy()
@Component({
  selector: 'bms-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit {
  routeParentMenuName: { [key in RouteParentMenuNameEnum]?: boolean } = {};
  routeParentMenuNameEnum: typeof RouteParentMenuNameEnum = RouteParentMenuNameEnum;


  constructor(
    private $router: Router,
    private $route: ActivatedRoute,
  ) {
    this.$router.events.pipe(
      untilDestroyed(this),

      filter(event => event instanceof NavigationEnd),
      map(() => this.$route),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
    ).subscribe((route) => {
      route.data.subscribe(
        (data: RouteData) => {

          if (data.parentMenuName !== undefined) {
            this.toggleMenu(
              data.parentMenuName,
              true
            );
          }

        }
      );
    });
  }


  ngOnInit(): void {
  }


  toggleMenu(
    menu: RouteParentMenuNameEnum,
    forceTo?: boolean,
  ) {

    if (!this.routeParentMenuName.hasOwnProperty(menu)) {
      this.routeParentMenuName[menu] = forceTo !== undefined
        ? forceTo
        : true;
    } else {
      this.routeParentMenuName[menu] = forceTo !== undefined
        ? forceTo
        : !this.routeParentMenuName[menu];
    }

  }
}
