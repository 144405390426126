import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';
import {delay, dematerialize, materialize, mergeMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ApplicationInterface} from '../../../application/application-list/application-list.component';
import {ApplyFormInterface, ApplyFormPoolFieldInterface} from '../../../apply-form/apply-form-entities.interface';
import {WidgetInterface} from '../../../dashboard/widget/widget-interface';
import {
  RecruitingProcessActionInterface,
  RecruitingProcessInterface,
  RecruitingProcessStepConnectionInterface,
  RecruitingProcessStepInterface
} from '../../../process-generator/process-entities.interface';
import {UserGroupInterface, UserGroupPermissionInterface, UserPermissionInterface} from '../../../user-management/entities';
import {ApplicationCreateResponseInterface} from '../api-response.interface';
import {applicationsFixture} from './organisations/applications/applications.fixture';
import {applyFormFixture, applyFormPoolFieldFixture} from './organisations/apply-form/apply-form.fixture';
import {organisationGroupPermissionsFixtures} from './organisations/groups/group-permissions.fixture';
import {organisationGroupsFixture} from './organisations/groups/groups.fixture';
import {jobAdsStatusDefinition} from './organisations/job-ads-status-definition/job-ads-status-definition.fixture';
import {jobAdsTypeDefinition} from './organisations/job-ads-type-definition/job-ads-type-definition.fixture';
import {jobAdsFixture} from './organisations/job-ads/job-ads.fixture';
import {organisationOrganisationLevelFixture} from './organisations/organisation-level/organisation-level.fixture';
import {
  processActionFixture,
  processStepConnectionsFixture,
  processStepFixture
} from './organisations/recruiting-processes/id/process-step.fixture';

import {
  recruitingProcessesActionsFixture,
  recruitingProcessesFixture
} from './organisations/recruiting-processes/recruiting-processes.fixture';
import {searchTagsFixture} from './organisations/search-tags/search-tags.fixture';
import {organisationUsersPermissionFixture} from './organisations/users/users-permission.fixture';
import {organisationUsersFixture} from './organisations/users/users.fixture';
import {emails} from './user/emails/emails';
import {notifications} from './user/notifications/notifications';
import {profile} from './user/profile/profile';
import {widgetsFixture} from './user/widgets/widgets.fixture';


/**
 * @link https://jasonwatmore.com/post/2020/07/18/angular-10-fake-backend-example-for-backendless-development#:~:text=A%20fake%20backend%20is%20used,before%20the%20backend%20is%20available.
 */

@Injectable()
export class FakeBackendInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const {url, method, headers, body} = request;

    // forward every request to an asset to the actual server.
    if (url.startsWith('assets/')) {
      return next.handle(request);
    }

    const urlObject = new URL(url);
    // delay response between 200 and 1000 ms with a delay threshold of 1 (default)
    const requestDelay = (
      Math.floor(
        Math.random() * (
          (1000 * environment.fakeApiDelayThreshold) - (200 * environment.fakeApiDelayThreshold)
        )
      ) + (200 * environment.fakeApiDelayThreshold)
    );


    // wrap in delayed observable to simulate server api call
    return of(null)
      .pipe(mergeMap(handleRoute))
      .pipe(materialize()) // call materialize and dematerialize to ensure delay even if an error is thrown (https://github.com/Reactive-Extensions/RxJS/issues/648)
      .pipe(delay(requestDelay))
      .pipe(dematerialize());


    function handleRoute() {
      switch (true) {
        case test(urlObject.pathname, '/organisations/{{uuid}}/applications') && method === 'GET':
          return organisationApplicationsGetAll();

        case test(urlObject.pathname, '/organisations/{{uuid}}/applications') && method === 'POST':
          return organisationApplicationCreate(
            body,
          );

        case test(urlObject.pathname, '/organisations/{{uuid}}/applications/{{uuid}}') && method === 'PUT':
          return organisationApplicationReplace(
            urlObject.pathname.split('/')[6],
            body,
          );

        case test(urlObject.pathname, '/organisations/{{uuid}}/applications/{{uuid}}') && method === 'DELETE':
          return organisationApplicationDelete(
              urlObject.pathname.split('/')[6]
          );


        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-forms') && method === 'POST':
          return organisationApplyFormCreate(
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-forms') && method === 'GET':
          return organisationApplyFormGetAll();
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-forms/{{uuid}}') && method === 'GET':
          return organisationApplyFormGet(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-forms/{{uuid}}') && method === 'PUT':
          return organisationApplyFormReplace(
            urlObject.pathname.split('/')[6],
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-forms/{{uuid}}') && method === 'DELETE':
          return organisationApplyFormDelete(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-form-pool-fields') && method === 'POST':
          return organisationApplyFormPoolFieldCreate(
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-form-pool-fields') && method === 'GET':
          return organisationApplyFormPoolFieldGetAll();
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-form-pool-fields/{{uuid}}') && method === 'GET':
          return organisationApplyFormPoolFieldGet(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-form-pool-fields/{{uuid}}') && method === 'PUT':
          return organisationApplyFormPoolFieldReplace(
            urlObject.pathname.split('/')[6],
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/apply-form-pool-fields/{{uuid}}') && method === 'DELETE':
          return organisationApplyFormPoolFieldDelete(
            urlObject.pathname.split('/')[6],
          );

        case test(urlObject.pathname, '/organisations/{{uuid}}/user-groups') && method === 'GET':
          return organisationUserGroupsGetAll();
        case test(urlObject.pathname, '/organisations/{{uuid}}/user-groups/{{uuid}}') && method === 'GET':
          return organisationUserGroupsGet(
            urlObject.pathname.split('/')[6],
          );

        case test(urlObject.pathname, '/organisations/{{uuid}}/user-groups') && method === 'POST':
          return organisationUserGroupCreate(
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/user-groups/{{uuid}}') && method === 'PUT':
          return organisationUserGroupReplace(
            urlObject.pathname.split('/')[6],
            body,
          );

        case test(urlObject.pathname, '/organisations/{{uuid}}/user-groups/{{uuid}}') && method === 'DELETE':
          return organisationUserGroupDelete(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/user-groups/{{uuid}}/permissions') && method === 'GET':
          return organisationUserGroupsPermissionsGetAll(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/user-groups/{{uuid}}/permissions') && method === 'PUT':
          return organisationUserGroupsPermissionsReplace(
            urlObject.pathname.split('/')[6],
            body,
          );

        case test(urlObject.pathname, '/organisations/{{uuid}}/job-ads') && method === 'GET':
          return organisationJobAdsGetAll();
        case test(urlObject.pathname, '/organisations/{{uuid}}/job-ads-status-definition') && method === 'GET':
          return organisationJobAdsStatusDefinitionGetAll();
        case test(urlObject.pathname, '/organisations/{{uuid}}/job-ads-type-definition') && method === 'GET':
          return organisationJobAdsTypeDefinitionGetAll();

        case test(urlObject.pathname, '/organisations/{{uuid}}/organisation-levels') && method === 'GET':
          return organisationOrganisationLevelGetAll();

        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes') && method === 'POST':
          return organisationRecruitingProcessCreate(
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes') && method === 'GET':
          return organisationRecruitingProcessesGetAll();
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}') && method === 'GET':
          return organisationRecruitingProcessGet(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}') && method === 'PUT':
          return organisationRecruitingProcessReplace(
            urlObject.pathname.split('/')[6],
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}') && method === 'DELETE':
          return organisationRecruitingProcessDelete(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/actions') && method === 'GET':
          return organisationRecruitingProcessesActionsGetAll();
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/actions/{{uuid}}') && method === 'GET':
          return organisationRecruitingProcessesActionsGet(
            urlObject.pathname.split('/')[7],
            urlObject.searchParams,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/step-connections') && method === 'POST':
          return organisationRecruitingProcessConnectionsCreate(
            urlObject.pathname.split('/')[6],
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/step-connections') && method === 'GET':
          return organisationRecruitingProcessConnectionsGetAll(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/step-connections/{{uuid}}') && method === 'DELETE':
          return organisationRecruitingProcessConnectionDelete(
            urlObject.pathname.split('/')[6],
            urlObject.pathname.split('/')[8],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps') && method === 'POST':
          return organisationRecruitingProcessStepsCreate(
            urlObject.pathname.split('/')[6],
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps') && method === 'GET':
          return organisationRecruitingProcessStepsGetAll(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps/{{uuid}}') && method === 'GET':
          return organisationRecruitingProcessStepsGet(
            urlObject.pathname.split('/')[6],
            urlObject.pathname.split('/')[8],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps/{{uuid}}') && method === 'PUT':
          return organisationRecruitingProcessStepReplace(
            urlObject.pathname.split('/')[6],
            urlObject.pathname.split('/')[8],
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps/{{uuid}}') && method === 'DELETE':
          return organisationRecruitingProcessStepDelete(
            urlObject.pathname.split('/')[6],
            urlObject.pathname.split('/')[8],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps/{{uuid}}/actions') && method === 'POST':
          return organisationRecruitingProcessActionsCreate(
            urlObject.pathname.split('/')[6],
            urlObject.pathname.split('/')[8],
            body
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps/{{uuid}}/actions') && method === 'GET':
          return organisationRecruitingProcessActionsGetAll(
            urlObject.pathname.split('/')[6],
            urlObject.pathname.split('/')[8],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps/{{uuid}}/actions/{{uuid}}') && method === 'PUT':
          return organisationRecruitingProcessActionReplace(
            urlObject.pathname.split('/')[6],
            urlObject.pathname.split('/')[8],
            urlObject.pathname.split('/')[10],
            body,
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/recruiting-processes/{{uuid}}/steps/{{uuid}}/actions/{{uuid}}') && method === 'DELETE':
          return organisationRecruitingProcessActionDelete(
            urlObject.pathname.split('/')[6],
            urlObject.pathname.split('/')[8],
            urlObject.pathname.split('/')[10],
          );

        case test(urlObject.pathname, '/organisations/{{uuid}}/search-tags') && method === 'GET':
          return organisationSearchTagsGetAll();

        case test(urlObject.pathname, '/organisations/{{uuid}}/search-tags') && method === 'POST':
          return organisationSearchTagCreate(
            body,
          );

        case test(urlObject.pathname, '/organisations/{{uuid}}/users') && method === 'GET':
          return organisationUsersGetAll();
        case test(urlObject.pathname, '/organisations/{{uuid}}/users/{{uuid}}') && method === 'GET':
          return organisationUsersGet(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/users/{{uuid}}') && method === 'DELETE':
          return organisationUserDelete(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/users/{{uuid}}/permissions') && method === 'GET':
          return organisationUsersPermissionGetAll(
            urlObject.pathname.split('/')[6],
          );
        case test(urlObject.pathname, '/organisations/{{uuid}}/users/{{uuid}}/permissions') && method === 'PUT':
          return organisationUsersPermissionReplace(
            urlObject.pathname.split('/')[6],
            body,
          );

        case test(urlObject.pathname, '/user/profile') && method === 'GET':
          return userProfileGet();
        case test(urlObject.pathname, '/user/emails') && method === 'GET':
          return userEmailsGetAll(
            urlObject.searchParams
          );
        case test(urlObject.pathname, '/user/notifications') && method === 'GET':
          return userNotificationsGetAll(
            urlObject.searchParams
          );
        case test(urlObject.pathname, '/user/widgets') && method === 'POST':
          return userWidgetsCreate(
            body
          );
        case test(urlObject.pathname, '/user/widgets') && method === 'GET':
          return userWidgetsGetAll();
        case test(urlObject.pathname, '/user/widgets/{{uuid}}') && method === 'PUT':
          return userWidgetsReplace(
            urlObject.pathname.split('/')[5],
            body,
          );
        case test(urlObject.pathname, '/user/widgets/{{uuid}}') && method === 'DELETE':
          return userWidgetsDelete(
            urlObject.pathname.split('/')[5],
          );
        default:
          // pass through any requests not handled above
          return next.handle(request);
      }
    }


    // route functions

    function organisationApplicationsGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(applicationsFixture)
      );
    }

    function organisationApplicationCreate(
      body: ApplicationInterface,
    ): Observable<HttpEvent<any>> {
      let now = new Date();
      body.id = self.crypto.randomUUID();
      body.jobApplicationLastUpdateDateTime = now.toISOString();
      body.jobApplicationReceivedDateTime = now.toISOString();
      applicationsFixture.push(copy(body));
      return successCreate(
        <ApplicationCreateResponseInterface>{id: body.id}
      );
    }


    function organisationApplicationReplace(
      applicationId: string,
      body: ApplicationInterface,
    ): Observable<HttpEvent<any>> {
      let now = new Date();
      body.jobApplicationLastUpdateDateTime = now.toISOString();
      let index = applicationsFixture.findIndex(application => application.id === applicationId);
      let application = applicationsFixture.find(application => application.id === applicationId)!;
      let updatedData: ApplicationInterface = {...application, ...copy(body)};

      applicationsFixture.splice(index, 1);
      applicationsFixture.push(updatedData);
      return successUpdate();
    }



    function organisationApplicationDelete(
        applicationId: string
    ): Observable<HttpEvent<any>> {
      let index = applicationsFixture.findIndex(element => element.id === applicationId);
      applicationsFixture.splice(index, 1);

      return successDelete();
    }



    function organisationApplyFormCreate(
      body: ApplyFormInterface,
    ): Observable<HttpEvent<any>> {
      body.id = self.crypto.randomUUID();

      applyFormFixture.push(body);

      return successCreate(
        <any>body
      );
    }


    function organisationApplyFormGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(applyFormFixture).sort(() => (Math.random() > .5) ? 1: -1)
      );
    }


    function organisationApplyFormGet(
      applyFormId: string
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(applyFormFixture.find(element => element.id === applyFormId))
      );
    }


    function organisationApplyFormReplace(
      applyFormId: string,
      body: ApplyFormInterface,
    ): Observable<HttpEvent<any>> {

      let applyForm = applyFormFixture.find(element => element.id === applyFormId)!;
      applyForm.title = body.title;
      applyForm.description = body.description;
      applyForm.formFields = body.formFields;

      return successUpdate(
        <any>body
      );
    }


    function organisationApplyFormDelete(
      applyFormId: string
    ): Observable<HttpEvent<any>> {

      let index = applyFormFixture.findIndex(element => element.id === applyFormId);
      applyFormFixture.splice(index, 1);

      return successDelete();
    }


    function organisationApplyFormPoolFieldCreate(
      body: ApplyFormPoolFieldInterface,
    ): Observable<HttpEvent<any>> {
      body.id = self.crypto.randomUUID();

      applyFormPoolFieldFixture.push(body);

      return successCreate(
        <any>body
      );
    }

    function organisationApplyFormPoolFieldGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(applyFormPoolFieldFixture).sort(() => (Math.random() > .5) ? 1: -1)
      );
    }

    function organisationApplyFormPoolFieldGet(
      applyFormPoolFieldId: string
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(applyFormPoolFieldFixture.find(element => element.id === applyFormPoolFieldId))
      );
    }

    function organisationApplyFormPoolFieldReplace(
      applyFormPoolFieldId: string,
      body: ApplyFormPoolFieldInterface,
    ): Observable<HttpEvent<any>> {
      let applyFormPoolField = applyFormPoolFieldFixture.find(element => element.id === applyFormPoolFieldId)!;
      applyFormPoolField.nameExternal = body.nameExternal;
      applyFormPoolField.nameApi = body.nameApi;
      applyFormPoolField.settings = body.settings;

      return successUpdate(
        <any>body
      );
    }

    function organisationApplyFormPoolFieldDelete(
      applyFormPoolFieldId: string
    ): Observable<HttpEvent<any>> {
      let index = applyFormPoolFieldFixture.findIndex(element => element.id === applyFormPoolFieldId);
      applyFormPoolFieldFixture.splice(index, 1);

      return successDelete();
    }

    function organisationUserGroupsGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(organisationGroupsFixture)
          .sort(() => (Math.random() > .5) ? 1: -1)
      );
    }

    function organisationUserGroupsGet(
      groupId: string,
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(organisationGroupsFixture.find(group => group.id === groupId))
      );
    }

    function organisationUserGroupCreate(
      body: UserGroupInterface,
    ): Observable<HttpEvent<any>> {
      body.id = self.crypto.randomUUID();

      organisationGroupsFixture.push(body);

      return successCreate(
        <any>body
      );
    }

    function organisationUserGroupReplace(
      groupId: string,
      body: UserGroupInterface,
    ): Observable<HttpEvent<any>> {

      let group = organisationGroupsFixture.find(element => element.id === groupId)!;
      group.name = body.name;
      group.sort = body.sort;

      return successUpdate(
        <any>body
      );
    }

    function organisationUserGroupDelete(
      groupId: string,
    ): Observable<HttpEvent<any>> {
      let index = organisationGroupsFixture.findIndex(element => element.id === groupId);
      organisationGroupsFixture.splice(index, 1);
      return successDelete();
    }

    function organisationUserGroupsPermissionsGetAll(
      groupId: string,
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(organisationGroupPermissionsFixtures[groupId])
      );
    }

    function organisationUserGroupsPermissionsReplace(
      groupId: string,
      permissions: UserGroupPermissionInterface
    ): Observable<HttpEvent<any>> {
      organisationGroupPermissionsFixtures[groupId] = permissions;

      return successUpdate();
    }

    function organisationJobAdsGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(jobAdsFixture)
          .sort(() => (Math.random() > .5) ? 1: -1)
      );
    }

    function organisationJobAdsStatusDefinitionGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(jobAdsStatusDefinition)
          .sort(() => (Math.random() > .5) ? 1: -1)
      );
    }

    function organisationJobAdsTypeDefinitionGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(jobAdsTypeDefinition)
          .sort(() => (Math.random() > .5) ? 1: -1)
      );
    }

    function organisationOrganisationLevelGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(organisationOrganisationLevelFixture)
          .sort(() => (Math.random() > .5) ? 1: -1)
      );
    }


    function organisationRecruitingProcessCreate(
      body: RecruitingProcessInterface,
    ): Observable<HttpEvent<any>> {
      body.id = self.crypto.randomUUID();

      recruitingProcessesFixture.push(body);
      processActionFixture[body.id] = {};
      processStepFixture[body.id] = [];
      processStepConnectionsFixture[body.id] = [];

      return successCreate(
        <any>body
      );
    }


    function organisationRecruitingProcessesGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(recruitingProcessesFixture).sort(() => (Math.random() > .5) ? 1: -1)
      );
    }


    function organisationRecruitingProcessGet(
      processId: string
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(recruitingProcessesFixture.find(element => element.id === processId))
      );
    }


    function organisationRecruitingProcessReplace(
      processId: string,
      body: RecruitingProcessInterface,
    ): Observable<HttpEvent<any>> {

      let process = recruitingProcessesFixture.find(element => element.id === processId)!;
      process.name = body.name;
      process.applyFormId = body.applyFormId;

      return successUpdate(
        <any>body
      );
    }


    function organisationRecruitingProcessDelete(
      processId: string
    ): Observable<HttpEvent<any>> {

      let index = recruitingProcessesFixture.findIndex(element => element.id === processId);
      recruitingProcessesFixture.splice(index, 1);

      return successDelete();
    }


    function organisationRecruitingProcessesActionsGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(recruitingProcessesActionsFixture)
          .map(action => {
            // this API response does not include the input and -outputActionParameters
            // to keep the fixture as simple as possible we just remove both properties here.
            delete action.inputActionParameters;
            delete action.outputActionParameters;
            return action;
          })
          .sort(() => (Math.random() > .5) ? 1: -1)
      );
    }


    function organisationRecruitingProcessesActionsGet(
      actionId: string,
      searchParams: URLSearchParams,
    ): Observable<HttpEvent<any>> {

      let result = copy(recruitingProcessesActionsFixture)
        .find(action => action.id === actionId)!;

      if (searchParams.get('input-parameter') !== 'true') {
        delete result.inputActionParameters;
      }

      if (searchParams.get('output-parameter') !== 'true') {
        delete result.outputActionParameters;
      }

      return successGet(
        result
      );
    }


    function organisationRecruitingProcessActionsCreate(
      processId: string,
      processStepId: string,
      body: RecruitingProcessActionInterface
    ): Observable<HttpEvent<any>> {

      body = copy(body);
      body.id = self.crypto.randomUUID();

      processActionFixture[processId][processStepId].push(body);

      return successCreate(
        <any>body
      );
    }


    function organisationRecruitingProcessActionsGetAll(
      processId: string,
      processStepId: string,
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(processActionFixture?.[processId]?.[processStepId] || []).sort(() => (Math.random() > .5) ? 1: -1)
      );
    }


    function organisationRecruitingProcessActionReplace(
      processId: string,
      processStepId: string,
      processStepActionId: string,
      body: RecruitingProcessActionInterface,
    ): Observable<HttpEvent<any>> {

      let action = processActionFixture[processId][processStepId].find(element => element.id === processStepActionId)!;
      action.values = body.values;

      return successUpdate(
        <any>body
      );
    }


    function organisationRecruitingProcessActionDelete(
      processId: string,
      processStepId: string,
      processStepActionId: string,
    ): Observable<HttpEvent<any>> {

      let index = processActionFixture[processId][processStepId].findIndex(element => element.id === processStepActionId)!;
      processActionFixture[processId][processStepId].splice(index, 1);

      return successDelete();
    }


    function organisationRecruitingProcessConnectionsCreate(
      processId: string,
      body: RecruitingProcessStepConnectionInterface
    ): Observable<HttpEvent<any>> {

      body.id = self.crypto.randomUUID();

      processStepConnectionsFixture[processId].push(body);

      return successCreate(
        <any>body
      );
    }


    function organisationRecruitingProcessConnectionsGetAll(
      processId: string
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(processStepConnectionsFixture?.[processId] || []).sort(() => (Math.random() > .5) ? 1: -1)
      );
    }


    function organisationRecruitingProcessConnectionDelete(
      processId: string,
      processConnectionId: string,
    ): Observable<HttpEvent<any>> {

      let index = processStepConnectionsFixture[processId].findIndex(element => element.id === processConnectionId);
      processStepConnectionsFixture[processId].splice(index, 1);

      return successDelete();
    }


    function organisationRecruitingProcessStepsCreate(
      processId: string,
      body: RecruitingProcessStepInterface
    ): Observable<HttpEvent<any>> {

      body.id = self.crypto.randomUUID();

      processStepFixture[processId].push(body);

      return successCreate(
        <any>body
      );
    }


    function organisationRecruitingProcessStepsGetAll(
      processId: string,
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(processStepFixture?.[processId] || []).sort(() => (Math.random() > .5) ? 1: -1)
      );
    }


    function organisationRecruitingProcessStepsGet(
      processId: string,
      processStepId: string,
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(processStepFixture[processId].find(element => element.id === processStepId))
      );
    }


    function organisationRecruitingProcessStepReplace(
      processId: string,
      processStepId: string,
      body: RecruitingProcessStepInterface,
    ): Observable<HttpEvent<any>> {

      let processStep = processStepFixture[processId].find(element => element.id === processStepId)!;
      processStep.name = body.name;
      processStep.abbreviation = body.abbreviation;
      processStep.backgroundColor = body.backgroundColor;
      processStep.positionX = body.positionX;
      processStep.positionY = body.positionY;
      processStep.isStart = body.isStart;
      processStep.isTerminateProcess = body.isTerminateProcess;
      processStep.isAllConnection = body.isAllConnection;

      return successUpdate(
        <any>body
      );
    }


    function organisationRecruitingProcessStepDelete(
      processId: string,
      processStepId: string,
    ): Observable<HttpEvent<any>> {

      let index = processStepFixture[processId].findIndex(element => element.id === processStepId);
      processStepFixture[processId].splice(index, 1);

      return successDelete();
    }

    function organisationSearchTagsGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(searchTagsFixture).sort(() => (Math.random() > .5) ? 1: -1)
      );
    }

    function organisationSearchTagCreate(
      body: any,
    ): Observable<HttpEvent<any>> {
      body.id = self.crypto.randomUUID();
      searchTagsFixture.push(body);
      return successCreate(
        <any>body
      );
    }

    function organisationUsersGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(organisationUsersFixture)
          .sort(() => (Math.random() > .5) ? 1: -1)
      );
    }

    function organisationUsersGet(
      userId: string,
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(organisationUsersFixture)
          .find(
            user => user.id === userId
          )
      );
    }

    function organisationUserDelete(
      userId: string,
    ): Observable<HttpEvent<any>> {
      let index = organisationUsersFixture.findIndex(element => element.id === userId);
      organisationUsersFixture.splice(index, 1);
      return successDelete();
    }

    function organisationUsersPermissionGetAll(
      userId: string,
    ): Observable<HttpEvent<any>> {
      return successGet(
        copy(organisationUsersPermissionFixture)
          .filter(
            permission => permission.userId === userId
          )
      );
    }

    function organisationUsersPermissionReplace(
      userId: string,
      body: Array<UserPermissionInterface>
    ): Observable<HttpEvent<any>> {

      // copy all permissions that are NOT assigned to the user
      const permissions = organisationUsersPermissionFixture.filter(
        permission => permission.userId !== userId
      );

      // add updated user permissions
      body.forEach(
        permission => {
          permissions.push(permission);
        }
      );

      // remove all permissions from fixture
      let length = organisationUsersPermissionFixture.length;
      while (length--) {
        organisationUsersPermissionFixture.splice(length, 1);
      }

      // reassign all permissions to fixture
      permissions.forEach(
        permission => organisationUsersPermissionFixture.push(permission)
      );

      return successUpdate();
    }

    function userProfileGet(): Observable<HttpEvent<any>> {
      return successGet(
        profile
      );
    }


    function userEmailsGetAll(
      urlSearchParams: URLSearchParams
    ): Observable<HttpEvent<any>> {
      let returnValue = emails;
      if (
        urlSearchParams.has('filter')
        && urlSearchParams.get('filter') === 'unread'
      ) {
        returnValue = returnValue.filter(email => !email.emailIsRead);
      }
      if (urlSearchParams.has('limit')) {
        returnValue = returnValue.splice(0, parseInt(urlSearchParams.get('limit')!));
      }
      return successGet(
        returnValue
      );
    }


    function userNotificationsGetAll(
      urlSearchParams: URLSearchParams
    ): Observable<HttpEvent<any>> {
      let returnValue = notifications;
      if (
        urlSearchParams.has('filter')
        && urlSearchParams.get('filter') === 'unread'
      ) {
        returnValue = returnValue.filter(notification => !notification.notificationIsRead);
      }
      if (urlSearchParams.has('limit')) {
        returnValue = returnValue.splice(0, parseInt(urlSearchParams.get('limit')!));
      }
      return successGet(
        returnValue
      );
    }


    function userWidgetsCreate(
      body: WidgetInterface
    ): Observable<HttpEvent<any>> {

      body.id = self.crypto.randomUUID();

      widgetsFixture.push(body);

      return successCreate(
        <any>body
      );
    }


    function userWidgetsGetAll(): Observable<HttpEvent<any>> {
      return successGet(
        copy(widgetsFixture).sort(() => (Math.random() > .5) ? 1: -1)
      );
    }


    function userWidgetsReplace(
      widgetId: string,
      body: WidgetInterface,
    ): Observable<HttpEvent<any>> {

      let widget = widgetsFixture.find(element => element.id === widgetId)!;
      widget.name = body.name;
      widget.type = body.type;
      widget.w = body.w;
      widget.h = body.h;
      widget.x = body.x;
      widget.y = body.y;
      widget.minW = body.minW;
      widget.minH = body.minH;
      widget.maxW = body.maxW;
      widget.maxH = body.maxH;

      return successUpdate(
        <any>body
      );
    }


    function userWidgetsDelete(
      widgetId: string,
    ): Observable<HttpEvent<any>> {

      let index = widgetsFixture.findIndex(element => element.id === widgetId);
      widgetsFixture.splice(index, 1);

      return successDelete();
    }


    // helper functions

    function successGet(body?: any) {
      return of(new HttpResponse({status: 200, body}));
    }


    function successCreate(body?: any) {
      return of(new HttpResponse({status: 201, body}));
    }


    function successUpdate(body?: any) {
      return of(new HttpResponse({status: 200, body}));
    }


    function successDelete() {
      return of(new HttpResponse({status: 204}));
    }


    function error(message: any) {
      return throwError(
        () => {
          const error: any = new Error(message);
          error.status = 400;
          error.error = {
            violations: message,
          };
          throw error;
        }
      );
    }


    function unauthorized() {
      return throwError(
        () => {
          const error: any = new Error('Unauthorised');
          error.status = 401;
          error.error = {
            violations: 'Unauthorised'
          };
          throw error;
        }
      );
    }


    function isLoggedIn() {
      return headers.get('Authorization') === 'Bearer fake-jwt-token';
    }


    function test(
      urlPathname: string,
      pattern: string,
    ): boolean {
      const uuid = '[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}';
      pattern = pattern.replace(new RegExp('{{uuid}}', 'gi'), uuid);
      pattern += '$';
      return !!urlPathname.match(new RegExp(pattern, 'gi'));
    }

    function copy<T>(source: T): T {
      return JSON.parse(JSON.stringify(source));
    }

  }
}


export const fakeBackendInterceptor = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: FakeBackendInterceptor,
  multi: true
};
