import {FilterInterface} from '../../../../../job-ad/job-ad-list/job-ad-list.component';

export let jobAdsStatusDefinition: FilterInterface[] = [
  {
    filterType: 'jobAdStatus',
    displayMenu: 'Status',
    displayStyle: '',
    filterData: [
      {
        id: 'e60ab4dc-a31f-4859-87e7-dd89f8456bb5',
        filterIsActive: false,
        filterParameters: '',
        displayColor: 'lightblue',
        displayLong: 'Beworben',
        displayShort: 'BEW',
        displayTooltip: 'Auf Stelle beworben',
      },
      {
        id: 'fcc83bda-fb83-4021-9b9c-dbd713c0acb4',
        filterIsActive: false,
        filterParameters: '',
        displayColor: 'lightsalmon',
        displayLong: 'Unterlagen',
        displayShort: 'UNT',
        displayTooltip: 'Unterlagen wurden nachgefordert',
      },
      {
        id: 'ec97aea9-4d38-4bbd-bb55-09a05f04f7c5',
        filterIsActive: false,
        filterParameters: '',
        displayColor: 'lightcoral',
        displayLong: 'Eingeladen',
        displayShort: 'LAD',
        displayTooltip: 'Bewerber wurde eingeladen',
      },
      {
        id: '9d79078b-5251-4671-8cb2-a9ca40555593',
        filterIsActive: false,
        filterParameters: '',
        displayColor: 'lightgreen',
        displayLong: 'Eingestellt',
        displayShort: 'EIN',
        displayTooltip: 'Bewerber wurde eingestellt',
      },
      {
        id: '94e57986-6e75-4906-870c-7d0ce1a02a3b',
        filterIsActive: false,
        filterParameters: '',
        displayColor: 'lightpink',
        displayLong: 'Abgelehnt',
        displayShort: 'ABS',
        displayTooltip: 'Bewerber wurde nicht eingestellt',
      },
      {
        id: 'd2cd744e-f228-4d3f-92b4-48ac319ba06b',
        filterIsActive: false,
        filterParameters: '',
        displayColor: 'lightyellow',
        displayLong: 'Abgesagt',
        displayShort: 'BAB',
        displayTooltip: 'Bewerber hat abgesagt',
      },
    ],
  }
];
