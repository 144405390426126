<mat-toolbar class="header">
  <button
      (click)="sidenav.toggle()"
      *ngIf="sidenav.mode === 'over'"
      mat-icon-button
  >
    <mat-icon *ngIf="!sidenav.opened">
      menu
    </mat-icon>
    <mat-icon *ngIf="sidenav.opened">
      close
    </mat-icon>
  </button>

  <bms-header
      fxLayout="row"
      fxLayoutAlign="space-between center"
  ></bms-header>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav="matSidenav">
    <bms-sidenav
        fxLayout="column"
        fxLayoutAlign="start stretch"
    >

    </bms-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="content">
      <router-outlet>

      </router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
