<button [matMenuTriggerFor]="notificationsMenu"
        mat-icon-button
>
  <mat-icon
      [matBadgeHidden]="counterNotifications <= 0"
      [matBadge]="counterNotifications < 9 ? counterNotifications : '9+'"
      matBadgeColor="warn"
  >notifications
  </mat-icon>
</button>

<mat-menu #notificationsMenu
          class="bms-custom-user-menu"
>
  <div (click)="openAllNotificationDetails()"
       class="menu-head"
       fxLayout="row"
       fxLayoutAlign="end center"
  >
    <div>Alle Benachrichtigungen anzeigen
      <mat-icon inline>view_list</mat-icon>
    </div>
  </div>

  <button (click)="openNotificationDetails(notification.notificationId)"
          *ngFor="let notification of notificationDisplayData"
          class="menu-item item-notification"
          fxLayout="column"
          fxLayoutAlign="start stretch"
          mat-menu-item
  >
    <div fxLayout="column"
         fxLayoutAlign="start start"
    >
      <div fxLayout="row"
           fxLayoutAlign="center center"
      >
        <jcd-avatar-photo [firstName]=notification.firstName
                          [lastName]=notification.lastName
                          [photoUrl]=notification.avatarLink
        ></jcd-avatar-photo>
        <div class="text-user"
             fxLayout="column"
             fxLayoutAlign="center start"
        >
          <div>{{notification.firstName}} {{notification.lastName}}</div>
          <div>{{notification.appliedAs}}</div>
          <div>{{notification.appliedCompany}}</div>
        </div>
      </div>
    </div>

    <div fxLayout="row"
         fxLayoutAlign="center center"
    >
      <div class="applicant-status">{{notification.notificationSubject}}</div>
    </div>

    <div class="text-sub"
         fxLayout="row"
         fxLayoutAlign="end stretch"
    >
      <div>
        <mat-icon inline>insert_invitation</mat-icon>
        {{getDateDisplay(notification.notificationDateTime)}}</div>
      <div>
        <mat-icon inline>access_time</mat-icon>
        {{notification.notificationDateTime | date:'HH:mm'}}</div>
    </div>
  </button>
</mat-menu>

