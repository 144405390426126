import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {JcdAvatarPhotoModule} from 'jcd-shared-components';
import {MaterialModule} from '../material.module';
import {HeaderComponent} from './header/header.component';
import {MenuEmailsComponent} from './menu-emails/menu-emails.component';
import {MenuNotificationsComponent} from './menu-notifications/menu-notifications.component';
import {MenuUserComponent} from './menu-user/menu-user.component';
import {SearchTagsComponent} from './search-tags/search-tags.component';
import {SidenavComponent} from './sidenav/sidenav.component';

@NgModule({
  declarations: [
    HeaderComponent,
    SidenavComponent,
    MenuEmailsComponent,
    MenuNotificationsComponent,
    MenuUserComponent,
    SearchTagsComponent,
  ],
    exports: [
        SidenavComponent,
        HeaderComponent,
        SearchTagsComponent,
    ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    JcdAvatarPhotoModule,
  ]
})
export class LayoutModule {
}
