// apply form
export const applyForm_itSpecialist_idFixture = 'bcbc51ca-dd5f-4e0a-a574-ccac58374f0e';


// groups
export const group_admin_idFixture = '02b8f0e1-1ebf-428c-98e3-000000000001';
export const group_vdEast_idFixture = '02b8f0e1-1ebf-428c-98e3-000000000002';
export const group_vdSouth_idFixture = '02b8f0e1-1ebf-428c-98e3-000000000003';
export const group_recruiterIt_idFixture = '02b8f0e1-1ebf-428c-98e3-000000000004';
export const group_recruiterService_idFixture = '02b8f0e1-1ebf-428c-98e3-000000000005';


// organisation level
export const organisationLevel_jcd_idFixture = '67e78a18-e2af-4358-912d-000000000001';
export const organisationLevel_vdSouth_idFixture = '67e78a18-e2af-4358-912d-000000000002';
export const organisationLevel_munich_idFixture = '67e78a18-e2af-4358-912d-000000000003';
export const organisationLevel_munichIt_idFixture = '67e78a18-e2af-4358-912d-000000000004';
export const organisationLevel_stuttgart_idFixture = '67e78a18-e2af-4358-912d-000000000005';
export const organisationLevel_stuttgartIt_idFixture = '67e78a18-e2af-4358-912d-000000000006';
export const organisationLevel_stuttgartService_idFixture = '67e78a18-e2af-4358-912d-000000000007';
export const organisationLevel_vdEast_idFixture = '67e78a18-e2af-4358-912d-000000000008';
export const organisationLevel_halle_idFixture = '67e78a18-e2af-4358-912d-000000000009';
export const organisationLevel_berlin_idFixture = '67e78a18-e2af-4358-912d-000000000010';


// recruiting processes
export const recruitingProcess_itSpecialist_idFixture = 'f40a11e3-8474-4372-a424-bc1ed23124c1';
export const recruitingProcess_itSpecialist_new_idFixture = '00000000-0000-4000-8000-000000000001';


// users
export const users_baumbachImmanuel_idFixture = '967537bc-45a1-4f5c-8af9-7eb6b2958bd7';
export const users_beerAntonetta_idFixture = '647123ff-6277-4e25-b0d3-60f8418ddef9';
