import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IconRegistryModule} from './layout/icon-registry.module';
import {LayoutModule} from './layout/layout.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    LayoutModule,
    IconRegistryModule,
  ],
  exports: [
    LayoutModule,
    IconRegistryModule,
  ]
})
export class FrameworkModule {
}
