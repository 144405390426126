import {JcdLanguage, JcdSalutation, JcdUserExtendedInterface} from 'jcd-shared-components';
import {users_baumbachImmanuel_idFixture, users_beerAntonetta_idFixture} from '../../fixture-ids.fixture';


export let organisationUsersFixture: Array<Omit<JcdUserExtendedInterface, 'lastSeen'> & { lastSeen: string }> = [{
  'id': '5bc38074-66a8-4a63-8701-25acdfef4408',
  'salutation': JcdSalutation.male,
  'firstName': 'Deanna',
  'lastName': 'Gibson',
  'emailAddress': 'Korey95@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Kiev',
  'isActive': false,
  'idSso': '5ca0d99f-3826-4df2-8cd6-9cb514297580',
  'lastSeen': '2022-02-17T13:12:50.214Z'
}, {
  'id': 'c40ecb85-622e-4dd9-b198-dda6a3d11bc1',
  'salutation': JcdSalutation.female,
  'firstName': 'Darien',
  'lastName': 'Welch',
  'emailAddress': 'Alfonzo22@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Africa/Cairo',
  'isActive': true,
  'idSso': 'a7bdf7bd-c171-4416-b8d9-1e6f18578029',
  'lastSeen': '2022-02-26T12:05:39.673Z'
}, {
  'id': 'bbfdd9e5-4616-43a5-94e2-ee8faa51cede',
  'salutation': JcdSalutation.female,
  'firstName': 'Alfonzo',
  'lastName': 'Mayer',
  'emailAddress': 'Joy70@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Mazatlan',
  'isActive': true,
  'idSso': '8975b404-f417-4391-b603-af1cc91a9820',
  'lastSeen': '2022-10-29T19:03:56.654Z'
}, {
  'id': '6bef7099-176e-4670-bafc-f6e4aefe2e1d',
  'salutation': JcdSalutation.male,
  'firstName': 'Unique',
  'lastName': 'Moen',
  'emailAddress': 'Destiny97@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Africa/Monrovia',
  'isActive': false,
  'idSso': '12a15b71-c987-4c1d-9c63-68c8ae1f1a26',
  'lastSeen': '2022-05-22T16:41:15.014Z'
}, {
  'id': '7025c00c-a262-4317-b709-19e6471b37ed',
  'salutation': JcdSalutation.female,
  'firstName': 'Lavon',
  'lastName': 'Kozey',
  'emailAddress': 'Evangeline_Herzog98@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Budapest',
  'isActive': true,
  'idSso': '832776be-323e-4b2e-af1c-c9fc481f3b57',
  'lastSeen': '2022-05-05T00:47:13.561Z'
}, {
  'id': '731f5362-8558-4e7c-8a70-f26774756668',
  'salutation': JcdSalutation.male,
  'firstName': 'Alexa',
  'lastName': 'Simonis',
  'emailAddress': 'Ryan.Turner43@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/St_Johns',
  'isActive': true,
  'idSso': '0d96e3f1-b593-42b1-a55a-bd5dc1881764',
  'lastSeen': '2022-02-16T10:26:59.455Z'
}, {
  'id': 'fc90b0db-5deb-43fe-bbb9-bafad06e2e4c',
  'salutation': JcdSalutation.female,
  'firstName': 'Tyra',
  'lastName': 'Funk',
  'emailAddress': 'Porter_Koelpin89@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Guatemala',
  'isActive': true,
  'idSso': '2ab59ac1-373d-4509-94aa-db28cf0321a9',
  'lastSeen': '2022-08-24T15:21:16.376Z'
}, {
  'id': '29611737-3fc5-4020-9766-29fb37c91aaa',
  'salutation': JcdSalutation.female,
  'firstName': 'Kristopher',
  'lastName': 'Rowe',
  'emailAddress': 'Mallory.OConner@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Riyadh',
  'isActive': true,
  'idSso': '3077c082-e790-4e41-b453-e7d308e755f9',
  'lastSeen': '2022-07-15T17:21:53.841Z'
}, {
  'id': users_beerAntonetta_idFixture,
  'salutation': JcdSalutation.male,
  'firstName': 'Antonetta',
  'lastName': 'Beer',
  'emailAddress': 'Keara_Bayer@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Africa/Johannesburg',
  'isActive': true,
  'idSso': 'de93953e-6a20-4f1f-9f96-a5565c218415',
  'lastSeen': '2021-12-26T22:49:09.941Z'
}, {
  'id': '67229f99-48a3-4bd8-878d-b3d4055e32c1',
  'salutation': JcdSalutation.male,
  'firstName': 'Loy',
  'lastName': 'Bauch',
  'emailAddress': 'Helmer31@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Australia/Sydney',
  'isActive': false,
  'idSso': 'f74c81dd-6a5c-4bbe-b1ba-f6c4cab1eeda',
  'lastSeen': '2022-03-14T08:52:44.459Z'
}, {
  'id': '2b7a0707-993e-49d9-b2a6-05f6059fdea6',
  'salutation': JcdSalutation.male,
  'firstName': 'Darien',
  'lastName': 'Reichel',
  'emailAddress': 'Eduardo85@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Africa/Harare',
  'isActive': false,
  'idSso': '8cbe7194-6e14-4ede-97e6-2fd912072f2a',
  'lastSeen': '2022-11-17T18:20:16.475Z'
}, {
  'id': '2b05ef69-dab3-4c43-92af-59a0cee7350b',
  'salutation': JcdSalutation.male,
  'firstName': 'Juanita',
  'lastName': 'Batz',
  'emailAddress': 'Orland.Parker@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'Europe/Prague',
  'isActive': false,
  'idSso': '89a7a03d-c57a-477a-8d70-858a99b3ade3',
  'lastSeen': '2022-06-11T00:38:16.484Z'
}, {
  'id': '27bef195-fce8-4ecc-a8f9-3b47b53a795d',
  'salutation': JcdSalutation.male,
  'firstName': 'Khalil',
  'lastName': 'Klein',
  'emailAddress': 'Junius_Kunze@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Riga',
  'isActive': false,
  'idSso': '2aacf0be-80a6-4893-b3e7-20f84b271f57',
  'lastSeen': '2022-11-12T01:01:50.142Z'
}, {
  'id': 'bda527c8-9ea7-4750-8086-25c9b3bc94b6',
  'salutation': JcdSalutation.male,
  'firstName': 'Rebekah',
  'lastName': 'Wilderman',
  'emailAddress': 'Koby92@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Chihuahua',
  'isActive': true,
  'idSso': 'e046d48b-f03b-4509-8185-a9c7a5127aa6',
  'lastSeen': '2022-07-04T17:01:02.787Z'
}, {
  'id': '59aee5a8-ba9a-493a-afa8-4e589c668728',
  'salutation': JcdSalutation.male,
  'firstName': 'Dorothea',
  'lastName': 'Buckridge',
  'emailAddress': 'Oran_Walker@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Rangoon',
  'isActive': false,
  'idSso': '788d7b5a-0e1b-4e7b-b2b4-83aedd69d01f',
  'lastSeen': '2022-05-11T06:59:06.775Z'
}, {
  'id': 'debc7edb-3d53-4ff7-95eb-ebfc796d744f',
  'salutation': JcdSalutation.female,
  'firstName': 'Marlen',
  'lastName': 'Kris',
  'emailAddress': 'Adolfo.Huels79@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Bangkok',
  'isActive': false,
  'idSso': '80e031e3-06a6-4d55-ac19-87cf541e0357',
  'lastSeen': '2022-01-27T10:40:57.713Z'
}, {
  'id': 'fa8a8b60-0ac6-4ef5-803a-0832b9d11fed',
  'salutation': JcdSalutation.male,
  'firstName': 'Coy',
  'lastName': 'Fadel',
  'emailAddress': 'Marcia70@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Helsinki',
  'isActive': true,
  'idSso': '558df94b-90c6-4e19-a862-a7b1b73ef340',
  'lastSeen': '2022-04-16T02:51:11.219Z'
}, {
  'id': '161ce710-e870-41b7-8e7d-2758cc12e64d',
  'salutation': JcdSalutation.female,
  'firstName': 'Morgan',
  'lastName': 'Stanton',
  'emailAddress': 'Celia82@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Caracas',
  'isActive': false,
  'idSso': '7fd01ead-d624-45a7-aca9-f144937457b6',
  'lastSeen': '2022-06-17T12:20:36.518Z'
}, {
  'id': '0720545c-0a5e-47af-bd5b-ba7e37e7d533',
  'salutation': JcdSalutation.female,
  'firstName': 'Rosario',
  'lastName': 'Kessler',
  'emailAddress': 'Aleen.Osinski38@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Pacific/Tongatapu',
  'isActive': false,
  'idSso': 'e0e1f012-8189-4d9b-a667-6123da4a5671',
  'lastSeen': '2022-08-09T21:47:13.434Z'
}, {
  'id': '496ba4e4-07d4-43d2-add3-3c8830a96c42',
  'salutation': JcdSalutation.male,
  'firstName': 'Robb',
  'lastName': 'Yundt',
  'emailAddress': 'Juston.Jast@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'Europe/London',
  'isActive': true,
  'idSso': '24ff2c0a-bbf4-4ac7-bdc7-6ffca109c2d9',
  'lastSeen': '2022-09-08T15:06:38.408Z'
}, {
  'id': '71f19209-4b07-439a-987c-df0c57624649',
  'salutation': JcdSalutation.female,
  'firstName': 'Ivah',
  'lastName': 'Legros',
  'emailAddress': 'Brown96@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Africa/Algiers',
  'isActive': true,
  'idSso': '4d2fa1fe-9259-48de-8ad5-ba6b5da91c92',
  'lastSeen': '2022-08-29T02:24:28.942Z'
}, {
  'id': 'b3bda89d-e25f-41fc-bab3-e4356564f1db',
  'salutation': JcdSalutation.female,
  'firstName': 'Dovie',
  'lastName': 'Stehr',
  'emailAddress': 'Marcellus66@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Yerevan',
  'isActive': false,
  'idSso': '7f826a24-aa92-409b-a132-78f1d30bf029',
  'lastSeen': '2022-06-03T05:00:24.253Z'
}, {
  'id': 'd0523b20-fd91-4903-a0eb-359b1699e405',
  'salutation': JcdSalutation.female,
  'firstName': 'Jeffry',
  'lastName': 'Gislason',
  'emailAddress': 'Laron_Reilly@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/St_Johns',
  'isActive': true,
  'idSso': '046d3000-5597-4ad9-8cee-7f85235b4eaa',
  'lastSeen': '2022-09-09T18:31:37.880Z'
}, {
  'id': '58c00a2d-3850-45cf-9a59-b224a3fa32f8',
  'salutation': JcdSalutation.female,
  'firstName': 'Mohamed',
  'lastName': 'Reynolds',
  'emailAddress': 'Jovanny99@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Vladivostok',
  'isActive': false,
  'idSso': 'e3e61acd-c3bf-4ca7-94ef-4fb8a79043e1',
  'lastSeen': '2022-04-21T23:07:16.868Z'
}, {
  'id': 'ab098f50-8f7a-4998-bd41-8ab56e8514a4',
  'salutation': JcdSalutation.male,
  'firstName': 'Sadye',
  'lastName': 'Kutch',
  'emailAddress': 'Julia65@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Kamchatka',
  'isActive': false,
  'idSso': 'cd71098b-7228-487b-8f0b-2fa79d3a2f61',
  'lastSeen': '2022-08-05T02:30:02.636Z'
}, {
  'id': '1a34bc57-0a69-48f5-aa56-11e8670a5d3b',
  'salutation': JcdSalutation.male,
  'firstName': 'Laurel',
  'lastName': 'Sipes',
  'emailAddress': 'Eveline78@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'Europe/Tallinn',
  'isActive': true,
  'idSso': '1504941c-91e2-45f4-ba0b-99662a2a030b',
  'lastSeen': '2022-02-21T20:07:16.125Z'
}, {
  'id': '361fefda-1bd9-454d-8e62-e676de02b121',
  'salutation': JcdSalutation.male,
  'firstName': 'Reyna',
  'lastName': 'Von',
  'emailAddress': 'Maximus73@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Yekaterinburg',
  'isActive': false,
  'idSso': '0003cfb5-7019-48e8-919b-57297463f138',
  'lastSeen': '2022-12-11T23:07:09.943Z'
}, {
  'id': 'ffd04644-3b0d-47f7-b27b-0fa83ff64e31',
  'salutation': JcdSalutation.male,
  'firstName': 'Bella',
  'lastName': 'Kutch',
  'emailAddress': 'Iva_Roob@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Africa/Casablanca',
  'isActive': true,
  'idSso': 'cb653af1-aebc-48ad-b844-b5aae33e32c3',
  'lastSeen': '2022-02-10T21:07:05.284Z'
}, {
  'id': '8b50770c-d4f8-4c3d-ad60-b9cb79be8ebb',
  'salutation': JcdSalutation.male,
  'firstName': 'Johathan',
  'lastName': 'Morar',
  'emailAddress': 'Josiah_Ebert52@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Bogota',
  'isActive': true,
  'idSso': '9c09c0bd-3a96-4c8f-a97b-6c5971c3dab5',
  'lastSeen': '2022-03-05T12:03:24.181Z'
}, {
  'id': '5257a82a-565f-4de2-83e5-fb9df4e6653c',
  'salutation': JcdSalutation.male,
  'firstName': 'Alexandria',
  'lastName': 'Jenkins',
  'emailAddress': 'Felton49@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Pacific/Tongatapu',
  'isActive': true,
  'idSso': 'bdb1b23f-a6f4-4bd4-bd6b-ade2b99c73b7',
  'lastSeen': '2022-07-10T06:05:09.325Z'
}, {
  'id': 'b6464e45-ab10-4159-b6c8-0db7ed5188d1',
  'salutation': JcdSalutation.male,
  'firstName': 'Leilani',
  'lastName': 'Erdman',
  'emailAddress': 'Eusebio_Anderson@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Africa/Monrovia',
  'isActive': false,
  'idSso': 'ffa6424e-20d5-4058-b11d-03d5938e48fd',
  'lastSeen': '2022-06-30T07:30:18.076Z'
}, {
  'id': '74c47b24-a8e5-4569-bd1d-592db80bf390',
  'salutation': JcdSalutation.male,
  'firstName': 'Myrtis',
  'lastName': 'Mosciski',
  'emailAddress': 'Neil_Powlowski@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Chihuahua',
  'isActive': true,
  'idSso': '6ce7add4-5bc6-46d7-aab6-bae41dc52d44',
  'lastSeen': '2022-10-02T05:59:21.244Z'
}, {
  'id': '43984d9d-86b3-4856-b3e7-979b9674d8ea',
  'salutation': JcdSalutation.female,
  'firstName': 'Lonnie',
  'lastName': 'Metz',
  'emailAddress': 'Milton_OKeefe18@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Pacific/Fakaofo',
  'isActive': false,
  'idSso': 'ba605709-7f15-401a-9555-0d57a1fab2bd',
  'lastSeen': '2022-10-24T15:46:26.156Z'
}, {
  'id': '909d7794-fa3e-498b-b7a7-4641cf21adc2',
  'salutation': JcdSalutation.female,
  'firstName': 'Carey',
  'lastName': 'Jacobson',
  'emailAddress': 'Amir87@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Santiago',
  'isActive': true,
  'idSso': '5f7b9b1e-4b2c-494c-864e-7414dbb645bd',
  'lastSeen': '2022-08-15T10:14:43.964Z'
}, {
  'id': 'd8bba602-4cfe-460d-9b50-703ca5a448e8',
  'salutation': JcdSalutation.female,
  'firstName': 'Meghan',
  'lastName': 'Rice',
  'emailAddress': 'Leonora_Wisoky@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Magadan',
  'isActive': false,
  'idSso': 'b461ebfa-b990-4693-b46a-628a3c945629',
  'lastSeen': '2022-05-15T10:16:15.118Z'
}, {
  'id': '03ac9297-6b35-4758-9097-5e82d93d33b8',
  'salutation': JcdSalutation.male,
  'firstName': 'Yessenia',
  'lastName': 'Bergnaum',
  'emailAddress': 'Ashtyn.Jacobs@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Monterrey',
  'isActive': false,
  'idSso': 'a8851ccb-3ce0-442c-baa9-b9300abd8be4',
  'lastSeen': '2022-09-26T21:32:41.238Z'
}, {
  'id': '19b645bf-b0a3-4934-ab25-7a6fb945deb0',
  'salutation': JcdSalutation.male,
  'firstName': 'Gia',
  'lastName': 'Bogan',
  'emailAddress': 'Lesley2@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Pacific/Tongatapu',
  'isActive': true,
  'idSso': '4c4b0cfc-dfb8-4136-b549-b6ba3c06911f',
  'lastSeen': '2022-05-16T20:01:42.627Z'
}, {
  'id': '4625b881-9f49-46a3-9d11-d6ad5c4a62b7',
  'salutation': JcdSalutation.male,
  'firstName': 'Christa',
  'lastName': 'Sipes',
  'emailAddress': 'Mariane4@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Chicago',
  'isActive': false,
  'idSso': 'cc9418ef-6c0e-4940-88d9-a17b360329fa',
  'lastSeen': '2022-04-22T07:30:37.719Z'
}, {
  'id': '7b5401e8-4af8-4ecc-b55f-ab19d2003b1c',
  'salutation': JcdSalutation.female,
  'firstName': 'Cali',
  'lastName': 'DuBuque',
  'emailAddress': 'Cleta52@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Tijuana',
  'isActive': false,
  'idSso': 'c7a41de6-227a-49a6-94e4-c4f7c9bd51d8',
  'lastSeen': '2022-05-25T21:37:04.134Z'
}, {
  'id': '88cc4fab-0e2d-4b2d-809a-05f108aae7f0',
  'salutation': JcdSalutation.male,
  'firstName': 'Jaden',
  'lastName': 'Kilback',
  'emailAddress': 'Salvatore_Langosh@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Almaty',
  'isActive': false,
  'idSso': 'fc816517-a21e-402f-bf25-dbd99998908c',
  'lastSeen': '2022-12-11T14:08:30.130Z'
}, {
  'id': '0c297dd3-bfaa-4951-a54c-7828179ba81d',
  'salutation': JcdSalutation.female,
  'firstName': 'Maybelle',
  'lastName': 'Haag',
  'emailAddress': 'Ebba.Oberbrunner@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Jerusalem',
  'isActive': true,
  'idSso': 'dc4dea6a-2a6b-4842-b6ce-d866a7a081a0',
  'lastSeen': '2022-08-12T21:04:09.592Z'
}, {
  'id': '24ff4628-3cf3-4e8f-b091-5f6356914a59',
  'salutation': JcdSalutation.male,
  'firstName': 'Dominic',
  'lastName': 'Price',
  'emailAddress': 'Mohammad55@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Vienna',
  'isActive': true,
  'idSso': 'ac1d4483-47ee-4cb9-bdd1-28d74f03ea9b',
  'lastSeen': '2022-08-08T21:14:35.216Z'
}, {
  'id': '4e7fea98-7418-4fda-9b9f-202135ad179e',
  'salutation': JcdSalutation.female,
  'firstName': 'Candida',
  'lastName': 'Pouros',
  'emailAddress': 'Ron24@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Riga',
  'isActive': true,
  'idSso': 'a9b799cf-285e-4622-94dd-5ef1d17bbe64',
  'lastSeen': '2022-10-22T04:32:16.338Z'
}, {
  'id': '45097b8e-6e3d-46d1-865b-e87fa216d0dd',
  'salutation': JcdSalutation.female,
  'firstName': 'Kristy',
  'lastName': 'Zulauf',
  'emailAddress': 'Selmer.Homenick@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Rome',
  'isActive': true,
  'idSso': 'fc7ab9be-6f76-4a5e-9c9a-8db634eead14',
  'lastSeen': '2022-10-22T23:16:31.796Z'
}, {
  'id': 'dd87423e-d3d5-438d-8a53-2c2da20d6f1a',
  'salutation': JcdSalutation.male,
  'firstName': 'Rory',
  'lastName': 'Bartell',
  'emailAddress': 'Katharina37@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Chihuahua',
  'isActive': false,
  'idSso': '7c2a4b41-ea4c-45f3-ad3a-d4f2bf2e588c',
  'lastSeen': '2022-08-18T19:44:13.263Z'
}, {
  'id': '9da3a93e-c2e5-4681-9872-32d3463aa29b',
  'salutation': JcdSalutation.female,
  'firstName': 'Laila',
  'lastName': 'Hermiston',
  'emailAddress': 'Oren_Wisoky@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Bogota',
  'isActive': true,
  'idSso': '87ba5c88-15f7-4655-aff1-2e2ee0650efd',
  'lastSeen': '2022-04-26T07:43:31.013Z'
}, {
  'id': '99e4fa49-1945-4384-8618-66beba71b958',
  'salutation': JcdSalutation.male,
  'firstName': 'Royal',
  'lastName': 'Emard',
  'emailAddress': 'Selena33@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Europe/Helsinki',
  'isActive': true,
  'idSso': '76bd2bde-6422-4f8d-be9a-a538cad9060f',
  'lastSeen': '2022-07-03T20:44:25.978Z'
}, {
  'id': 'c09668d8-7313-404d-aa3e-eef4ee2299b8',
  'salutation': JcdSalutation.male,
  'firstName': 'Cicero',
  'lastName': 'Stark',
  'emailAddress': 'Aleen_Bernier61@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Mexico_City',
  'isActive': false,
  'idSso': 'd3f9dbae-caee-4c8f-9ca1-aa6b45121509',
  'lastSeen': '2022-04-13T17:17:55.202Z'
}, {
  'id': '2e88110d-aa96-4b04-9bdf-42349c387b01',
  'salutation': JcdSalutation.male,
  'firstName': 'Tony',
  'lastName': 'Auer',
  'emailAddress': 'Ralph.Stanton@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Yekaterinburg',
  'isActive': false,
  'idSso': '0dd4cfbc-8470-44e2-91aa-b584afcdbf4f',
  'lastSeen': '2022-09-09T07:26:43.717Z'
}, {
  'id': '52ff5e83-5d65-4941-9d02-59bf7e82a036',
  'salutation': JcdSalutation.female,
  'firstName': 'Adam',
  'lastName': 'Sipes',
  'emailAddress': 'Louvenia_Grant@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Australia/Darwin',
  'isActive': true,
  'idSso': '3270d1a6-4daa-43e5-80e3-d48b057b9c05',
  'lastSeen': '2022-02-23T05:29:42.208Z'
}, {
  'id': 'aa3a7178-fedb-4915-9bda-6022e937d80d',
  'salutation': JcdSalutation.male,
  'firstName': 'Milo',
  'lastName': 'Fay',
  'emailAddress': 'Asia.Kris@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Europe/Berlin',
  'isActive': true,
  'idSso': '5f19aaf6-543f-4699-a5f7-528d68b892dc',
  'lastSeen': '2022-03-01T10:22:42.326Z'
}, {
  'id': '73117f3b-398c-4a2b-9790-074717342583',
  'salutation': JcdSalutation.male,
  'firstName': 'Nash',
  'lastName': 'Bradtke',
  'emailAddress': 'Mattie.Cremin45@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Magadan',
  'isActive': false,
  'idSso': 'f84fe957-e0a5-4780-86ad-36138c552a91',
  'lastSeen': '2022-01-18T07:32:01.078Z'
}, {
  'id': 'e26fa13e-c890-443d-a5b7-c14859bb351e',
  'salutation': JcdSalutation.male,
  'firstName': 'Sofia',
  'lastName': 'Predovic',
  'emailAddress': 'Keon.Schneider69@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'America/La_Paz',
  'isActive': false,
  'idSso': 'c541214b-8878-491c-b9ae-289476472fe6',
  'lastSeen': '2022-01-28T11:56:30.814Z'
}, {
  'id': '9b1b46ac-dead-4137-b014-2ced892123dc',
  'salutation': JcdSalutation.female,
  'firstName': 'Rosemarie',
  'lastName': 'Sporer',
  'emailAddress': 'Jovanny_Bruen@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Los_Angeles',
  'isActive': false,
  'idSso': '555fbcd4-8e65-45ea-b8a9-30c7c1b3765b',
  'lastSeen': '2022-03-23T21:33:32.060Z'
}, {
  'id': users_baumbachImmanuel_idFixture,
  'salutation': JcdSalutation.female,
  'firstName': 'Immanuel',
  'lastName': 'Baumbach',
  'emailAddress': 'Jody3@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Novosibirsk',
  'isActive': true,
  'idSso': 'cececc5c-96fc-4a22-a0e5-66fb44c264a1',
  'lastSeen': '2022-09-11T15:12:57.720Z'
}, {
  'id': '84230927-9ac5-45d3-a27a-0e18c8b4be38',
  'salutation': JcdSalutation.male,
  'firstName': 'Hallie',
  'lastName': 'Haley',
  'emailAddress': 'Renee.Conroy@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Lima',
  'isActive': true,
  'idSso': '0bd9e022-90cf-4cde-bee7-54c0d3cecb90',
  'lastSeen': '2022-03-01T09:48:42.560Z'
}, {
  'id': 'be94e867-e1e7-41e5-bfb3-8bb2cb059042',
  'salutation': JcdSalutation.male,
  'firstName': 'Trenton',
  'lastName': 'Homenick',
  'emailAddress': 'Tess3@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Africa/Algiers',
  'isActive': false,
  'idSso': '77deb8ca-163e-46ae-b4bc-6a7ae6406cee',
  'lastSeen': '2022-08-04T13:07:31.530Z'
}, {
  'id': 'e0889de9-e777-4e5b-9067-4adc9be406d9',
  'salutation': JcdSalutation.male,
  'firstName': 'Lessie',
  'lastName': 'Mills',
  'emailAddress': 'Kariane_Schiller15@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Riyadh',
  'isActive': true,
  'idSso': '2631241e-b967-4f18-8cc2-555bde8773da',
  'lastSeen': '2021-12-24T08:18:50.289Z'
}, {
  'id': 'dadc5053-b569-4a0d-aa1d-b245efa56f22',
  'salutation': JcdSalutation.female,
  'firstName': 'Cristian',
  'lastName': 'Lakin',
  'emailAddress': 'Merritt_Grady@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'Australia/Hobart',
  'isActive': true,
  'idSso': 'e368f48a-16de-421c-92b4-1aed6b05b9b2',
  'lastSeen': '2022-07-19T16:28:45.431Z'
}, {
  'id': 'e0907070-4111-48d6-926f-0e90e1e3c69f',
  'salutation': JcdSalutation.male,
  'firstName': 'Alysa',
  'lastName': 'Kris',
  'emailAddress': 'Max54@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'Europe/Kiev',
  'isActive': false,
  'idSso': '57fa249a-296d-46c9-b57c-d07dfea9bc37',
  'lastSeen': '2022-01-22T08:45:40.380Z'
}, {
  'id': '9f4a1a81-a13d-4f34-ae50-540a72a7c6b7',
  'salutation': JcdSalutation.female,
  'firstName': 'Maxine',
  'lastName': 'Emard',
  'emailAddress': 'Mustafa39@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Kathmandu',
  'isActive': false,
  'idSso': '11d0ecee-4a27-4e81-949f-d7bcc92cc386',
  'lastSeen': '2022-06-19T10:06:41.888Z'
}, {
  'id': 'abe1dba7-1d86-4f6d-b7bd-01f593febe51',
  'salutation': JcdSalutation.female,
  'firstName': 'Justice',
  'lastName': 'Morissette',
  'emailAddress': 'Gilbert31@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Kuwait',
  'isActive': false,
  'idSso': '3466cf79-9af0-4b8c-90a5-705f07dbf2a8',
  'lastSeen': '2022-10-14T13:58:07.708Z'
}, {
  'id': 'afc0271a-2813-469f-a8c0-517b3b98d24f',
  'salutation': JcdSalutation.male,
  'firstName': 'Weston',
  'lastName': 'Hodkiewicz',
  'emailAddress': 'Lilliana.McKenzie65@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Riyadh',
  'isActive': true,
  'idSso': '11503e8c-92c4-4605-a910-c2b7e3323164',
  'lastSeen': '2022-08-09T02:00:35.565Z'
}, {
  'id': '99e02eec-e94e-4fbe-9e2d-5bad172d8ad0',
  'salutation': JcdSalutation.female,
  'firstName': 'Jackson',
  'lastName': 'Casper',
  'emailAddress': 'Laurence94@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Sao_Paulo',
  'isActive': true,
  'idSso': 'c23b1276-9e65-47ad-b221-fa03dde1f25b',
  'lastSeen': '2022-04-20T11:38:17.084Z'
}, {
  'id': 'ee4894d5-fb8a-4faa-9edf-026ec9f77b1e',
  'salutation': JcdSalutation.female,
  'firstName': 'Amya',
  'lastName': 'Weber',
  'emailAddress': 'Terrill.Treutel55@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Atlantic/South_Georgia',
  'isActive': true,
  'idSso': '01c1914e-3c99-4b01-bd51-8baee81f8bac',
  'lastSeen': '2022-11-04T11:39:03.548Z'
}, {
  'id': '25711d3f-5bd9-4bd8-973e-eee93065f0c4',
  'salutation': JcdSalutation.female,
  'firstName': 'Branson',
  'lastName': 'Wuckert',
  'emailAddress': 'Warren_Maggio23@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Mazatlan',
  'isActive': true,
  'idSso': '310c8bde-dcbe-490b-9651-8a7050301b2c',
  'lastSeen': '2022-01-17T19:32:35.486Z'
}, {
  'id': '672f0bac-6834-4877-bbcd-d0a9a6366d3d',
  'salutation': JcdSalutation.male,
  'firstName': 'Rita',
  'lastName': 'Wunsch',
  'emailAddress': 'Salvador_Fadel42@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Hong_Kong',
  'isActive': true,
  'idSso': 'dbf6ec2b-4d0a-432f-b038-c8a7a3527422',
  'lastSeen': '2022-09-22T08:59:21.587Z'
}, {
  'id': '30cbf562-42f1-43d4-8006-40a8cb5740ea',
  'salutation': JcdSalutation.male,
  'firstName': 'German',
  'lastName': 'Graham',
  'emailAddress': 'Jennifer_Kuhn40@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Australia/Perth',
  'isActive': false,
  'idSso': '1dfe5b25-c60b-41a3-a11c-16d996bb486f',
  'lastSeen': '2022-11-17T23:21:51.628Z'
}, {
  'id': '9363aff6-6182-4983-82e1-bba75dd79ac5',
  'salutation': JcdSalutation.male,
  'firstName': 'Maymie',
  'lastName': 'Hayes',
  'emailAddress': 'Michaela.Hickle5@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Guyana',
  'isActive': false,
  'idSso': 'a989b16e-d8c1-41ed-b04e-7c53f4d216d8',
  'lastSeen': '2022-09-22T01:35:07.892Z'
}, {
  'id': 'b4559ecd-ccfe-4191-ab86-6e6b025ccc10',
  'salutation': JcdSalutation.female,
  'firstName': 'Yvonne',
  'lastName': 'Schultz',
  'emailAddress': 'Deron40@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Africa/Casablanca',
  'isActive': false,
  'idSso': '627d5c86-71ff-45ec-b5b0-f36c24897159',
  'lastSeen': '2022-09-30T18:15:35.758Z'
}, {
  'id': 'c9014c2f-24d4-46a6-9634-b63e42f1222b',
  'salutation': JcdSalutation.female,
  'firstName': 'Velva',
  'lastName': 'Kertzmann',
  'emailAddress': 'Ben.Little54@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Zagreb',
  'isActive': true,
  'idSso': '3a6d79ef-2c73-4435-9042-65f5ee733aac',
  'lastSeen': '2022-06-09T09:38:10.543Z'
}, {
  'id': 'f0d327e4-22ca-44de-8183-5475a9dd7ccb',
  'salutation': JcdSalutation.female,
  'firstName': 'Germaine',
  'lastName': 'Hansen',
  'emailAddress': 'Katlyn.Koss@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Madrid',
  'isActive': true,
  'idSso': '4a1a8f73-5b74-4a0f-afad-8e1a68e01d45',
  'lastSeen': '2022-03-11T23:06:26.101Z'
}, {
  'id': 'd81c5c6d-e732-4bbd-a0e0-f441651287bf',
  'salutation': JcdSalutation.male,
  'firstName': 'Guadalupe',
  'lastName': 'Huel',
  'emailAddress': 'Hosea56@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Halifax',
  'isActive': false,
  'idSso': 'fc25d1cf-9a15-4979-83a9-be38bbfdb4d2',
  'lastSeen': '2022-09-18T08:18:32.049Z'
}, {
  'id': 'afe6fdbd-919c-4f0c-9582-104d40235e2a',
  'salutation': JcdSalutation.male,
  'firstName': 'Casandra',
  'lastName': 'Beer',
  'emailAddress': 'Beulah_Rogahn88@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Tehran',
  'isActive': false,
  'idSso': 'cbdd4f55-05a8-4280-acbc-713ee960d4ed',
  'lastSeen': '2022-07-23T20:53:38.549Z'
}, {
  'id': 'a5d89aa4-5397-4229-8405-268dfab82c7c',
  'salutation': JcdSalutation.male,
  'firstName': 'Donavon',
  'lastName': 'Funk',
  'emailAddress': 'Colt.Hartmann27@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Pacific/Auckland',
  'isActive': true,
  'idSso': '99c0c0e6-c4e1-455d-aee2-383766b814ad',
  'lastSeen': '2022-12-09T10:46:43.317Z'
}, {
  'id': 'dea2760f-9fa0-4080-8032-77df6bf7da6f',
  'salutation': JcdSalutation.male,
  'firstName': 'August',
  'lastName': 'Harris',
  'emailAddress': 'Karl73@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Mazatlan',
  'isActive': false,
  'idSso': 'b44fb140-0eb5-4037-bf9e-e3e0a60817ed',
  'lastSeen': '2022-02-09T16:52:37.028Z'
}, {
  'id': '530369e6-3934-47ba-b202-57193035bbd2',
  'salutation': JcdSalutation.male,
  'firstName': 'Marlene',
  'lastName': 'Watsica',
  'emailAddress': 'Jordan_Klein@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Lima',
  'isActive': true,
  'idSso': '57bd805e-50fd-468c-9244-bf0f73ff9659',
  'lastSeen': '2022-06-11T19:56:12.168Z'
}, {
  'id': '1b283d6e-d127-4614-8720-319b74108da7',
  'salutation': JcdSalutation.male,
  'firstName': 'Veronica',
  'lastName': 'Turner',
  'emailAddress': 'Delbert10@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'Australia/Adelaide',
  'isActive': false,
  'idSso': '7230cabf-d579-4546-bc86-417838dd6cc2',
  'lastSeen': '2022-12-05T23:43:56.606Z'
}, {
  'id': 'ffc7e229-526e-4fb0-bb3b-0ec73c1adf3c',
  'salutation': JcdSalutation.female,
  'firstName': 'Kylie',
  'lastName': 'McLaughlin',
  'emailAddress': 'Consuelo.Kihn72@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Africa/Johannesburg',
  'isActive': true,
  'idSso': '2a449aaa-f7fc-4092-8f95-b50ab50c48e2',
  'lastSeen': '2022-06-28T02:24:12.910Z'
}, {
  'id': '167d2c6a-e92f-47bc-9f05-d61f99e86d6d',
  'salutation': JcdSalutation.male,
  'firstName': 'Margret',
  'lastName': 'Dicki',
  'emailAddress': 'Shea.Jaskolski57@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Indiana/Indianapolis',
  'isActive': true,
  'idSso': '03d741d0-f356-4219-8953-4e1590633b1e',
  'lastSeen': '2022-10-24T04:41:37.562Z'
}, {
  'id': '5e19bd35-7380-46d3-8e7d-d2d6179d4832',
  'salutation': JcdSalutation.female,
  'firstName': 'Walter',
  'lastName': 'Rogahn',
  'emailAddress': 'Dallas.Hackett@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Europe/Rome',
  'isActive': false,
  'idSso': '73f9b6d5-0f7c-4ee4-8943-026a56ded82b',
  'lastSeen': '2022-04-12T03:56:29.416Z'
}, {
  'id': '4bba0b4a-ae68-42e5-96cb-99f49fd7446e',
  'salutation': JcdSalutation.male,
  'firstName': 'Emerson',
  'lastName': 'Davis',
  'emailAddress': 'Ashley.Zemlak72@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Australia/Hobart',
  'isActive': true,
  'idSso': '2c4977b2-02d7-4972-a78d-fd550d0868de',
  'lastSeen': '2022-06-05T16:11:07.876Z'
}, {
  'id': '58f9bab9-2ba4-4c49-8e39-0157b0c2d129',
  'salutation': JcdSalutation.male,
  'firstName': 'Hipolito',
  'lastName': 'Jacobi',
  'emailAddress': 'Werner_Sawayn64@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Santiago',
  'isActive': false,
  'idSso': '3872a364-c4f1-4c55-a30e-a6315feadea2',
  'lastSeen': '2022-03-05T09:19:52.671Z'
}, {
  'id': '9e2b24a7-1d8c-40ab-b1b1-526ec7570e17',
  'salutation': JcdSalutation.female,
  'firstName': 'Lorna',
  'lastName': 'Jacobi',
  'emailAddress': 'Isabell54@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Bangkok',
  'isActive': false,
  'idSso': 'f819ca98-fdb1-4ad3-978d-0efa7b310258',
  'lastSeen': '2022-09-07T00:02:15.510Z'
}, {
  'id': '47304de1-f710-4902-9587-700fca49b768',
  'salutation': JcdSalutation.male,
  'firstName': 'Adan',
  'lastName': 'Thiel',
  'emailAddress': 'Marques_Ortiz45@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Santiago',
  'isActive': false,
  'idSso': '44e0393d-c220-4aaf-8b75-ab272a2b480a',
  'lastSeen': '2022-06-17T21:55:59.630Z'
}, {
  'id': '81f60e3e-38f5-459b-9321-b85e955bd050',
  'salutation': JcdSalutation.male,
  'firstName': 'Randy',
  'lastName': 'Fisher',
  'emailAddress': 'Abbey.Hahn@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Pacific/Fakaofo',
  'isActive': true,
  'idSso': 'd6c9bb3d-7351-4243-a4f8-91cdaee823ea',
  'lastSeen': '2022-09-02T13:15:03.791Z'
}, {
  'id': 'a577f5b5-a812-457a-a99a-22915973fdb2',
  'salutation': JcdSalutation.female,
  'firstName': 'Fleta',
  'lastName': 'Dicki',
  'emailAddress': 'Dax_Satterfield@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Tashkent',
  'isActive': false,
  'idSso': 'bc760f2c-ef90-4b07-8076-92828d81079c',
  'lastSeen': '2022-01-05T05:56:37.938Z'
}, {
  'id': '0724b941-f5e6-4106-94ae-7e469f6d596f',
  'salutation': JcdSalutation.male,
  'firstName': 'Brigitte',
  'lastName': 'Klein',
  'emailAddress': 'Cletus.Grant56@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Pacific/Tongatapu',
  'isActive': false,
  'idSso': '4ade3080-29e5-4ddf-bd53-9b4a07b829a3',
  'lastSeen': '2022-06-02T09:07:09.337Z'
}, {
  'id': '48adb0a3-8aa2-4a04-8a18-a22f8d525bf9',
  'salutation': JcdSalutation.female,
  'firstName': 'Amara',
  'lastName': 'Pollich',
  'emailAddress': 'Aditya_Hagenes@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Europe/Moscow',
  'isActive': true,
  'idSso': 'c8cf473b-3ea5-4480-b065-51669387dc54',
  'lastSeen': '2022-04-24T02:42:38.103Z'
}, {
  'id': 'e2286fe8-eecb-48a3-997d-338eff182bc2',
  'salutation': JcdSalutation.male,
  'firstName': 'Flavio',
  'lastName': 'Kunde',
  'emailAddress': 'Monique_Powlowski@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'Australia/Sydney',
  'isActive': false,
  'idSso': 'b3ab997e-e544-4cde-a4e3-9eb7f21c4a1c',
  'lastSeen': '2022-04-04T14:55:32.442Z'
}, {
  'id': '239120f1-daa9-4b2d-8dbb-59ab6debde39',
  'salutation': JcdSalutation.female,
  'firstName': 'Elaina',
  'lastName': 'Effertz',
  'emailAddress': 'Rosalyn68@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Kamchatka',
  'isActive': true,
  'idSso': 'fed46099-410d-40c2-9ffb-a4bc4354d83a',
  'lastSeen': '2022-09-16T17:54:01.767Z'
}, {
  'id': 'e2984a02-c8a5-48ef-8da1-6870d1d8833c',
  'salutation': JcdSalutation.male,
  'firstName': 'Lew',
  'lastName': 'Hagenes',
  'emailAddress': 'Broderick5@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Los_Angeles',
  'isActive': false,
  'idSso': '49ccc10c-bc30-4836-8d21-e8638456ba82',
  'lastSeen': '2022-12-07T16:09:14.586Z'
}, {
  'id': 'aa0d9625-d040-4f20-8419-3cb9402fa1b9',
  'salutation': JcdSalutation.male,
  'firstName': 'Heber',
  'lastName': 'Larson',
  'emailAddress': 'Karl2@hotmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Asia/Kamchatka',
  'isActive': false,
  'idSso': '41657c6c-7c0c-408e-863d-23d7a3a78a10',
  'lastSeen': '2022-01-10T07:29:25.753Z'
}, {
  'id': '7cd334ce-98ea-46e1-886e-1c66646f8cac',
  'salutation': JcdSalutation.male,
  'firstName': 'Eleanora',
  'lastName': 'Kutch',
  'emailAddress': 'Nicolette_Daugherty@gmail.com',
  'language': JcdLanguage.en,
  'timezone': 'Pacific/Fiji',
  'isActive': true,
  'idSso': '34b4d5eb-2696-4328-9e39-1b7053b0b371',
  'lastSeen': '2022-11-20T16:26:35.598Z'
}, {
  'id': '16ba2db4-e1fc-48cb-8975-9ebeddb8ed63',
  'salutation': JcdSalutation.male,
  'firstName': 'Erwin',
  'lastName': 'Herzog',
  'emailAddress': 'Selina_Stoltenberg@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Denver',
  'isActive': false,
  'idSso': '1774bda4-8588-45c9-bf6e-9b28de96d1e0',
  'lastSeen': '2022-01-15T19:09:24.855Z'
}, {
  'id': '696646fd-79d8-45a7-a5b4-85256ed8fc89',
  'salutation': JcdSalutation.male,
  'firstName': 'Carmela',
  'lastName': 'Willms',
  'emailAddress': 'Ladarius5@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Pacific/Fiji',
  'isActive': true,
  'idSso': '33546c57-ac62-4024-abbf-e6f5e449cb35',
  'lastSeen': '2022-12-05T22:36:35.643Z'
}, {
  'id': '13fcf934-4715-4b98-a2f1-b90b8e1a4071',
  'salutation': JcdSalutation.female,
  'firstName': 'Devan',
  'lastName': 'Langworth',
  'emailAddress': 'Evans_Robel97@gmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Asia/Vladivostok',
  'isActive': false,
  'idSso': 'b6545522-8114-4597-ab09-0762d28d0168',
  'lastSeen': '2022-01-20T08:16:26.946Z'
}, {
  'id': '23316d45-8c90-44c5-82e8-8d938179b894',
  'salutation': JcdSalutation.male,
  'firstName': 'Jakob',
  'lastName': 'Schumm',
  'emailAddress': 'Otto20@hotmail.com',
  'language': JcdLanguage.de,
  'timezone': 'Europe/Helsinki',
  'isActive': true,
  'idSso': '64620681-b079-4a74-abe1-8f2b675721fc',
  'lastSeen': '2022-10-31T17:07:10.374Z'
}, {
  'id': '00939675-d317-45cc-8141-361eb7dfb125',
  'salutation': JcdSalutation.female,
  'firstName': 'Shana',
  'lastName': 'Muller',
  'emailAddress': 'Aditya_Lindgren21@yahoo.com',
  'language': JcdLanguage.de,
  'timezone': 'America/Bogota',
  'isActive': true,
  'idSso': 'dccf585e-03f3-44be-ad53-36467c873576',
  'lastSeen': '2022-03-13T02:08:22.502Z'
}, {
  'id': 'b544fb5a-a7b1-4644-84d3-14ce786d2bca',
  'salutation': JcdSalutation.male,
  'firstName': 'Denis',
  'lastName': 'Kunde',
  'emailAddress': 'Oswaldo.Altenwerth@yahoo.com',
  'language': JcdLanguage.en,
  'timezone': 'America/Halifax',
  'isActive': false,
  'idSso': '336a6811-5a70-40b0-ba28-6ab8379f3aaf',
  'lastSeen': '2022-06-22T22:06:21.146Z'
}];
