<mat-form-field class="search-tag-field">
  <mat-chip-list #chipList aria-label="Tag auswahl" class="bms-mat-chip">
    <mat-chip
        *ngFor="let tag of selectedSearchTags"
        (removed)="remove(tag)">
      {{tag.name}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
        placeholder="Neues Keyword"
        #tagInput
        [formControl]="tagCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of selectableSearchTags" [value]="tag">
      {{tag.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
