import {NgModule} from '@angular/core';
import {Data, Route, RouterModule} from '@angular/router';
import {JcdNotFoundComponent} from 'jcd-shared-components';


export enum RouteParentMenuNameEnum {
  userManagement = 'userManagement',
}

export interface RouteData extends Data {
  /**
   * If the route is in a submenu of the main navigation bar you need to insert
   * its name here. This will open the parent menu and select the child as
   * currently active navigation element.
   *
   * Note: you can add this to the parent or child route, depending on your
   * use-case.
   */
  parentMenuName?: RouteParentMenuNameEnum;
}

export interface AppRoute extends Route {
  data?: RouteData;
}


const routes: AppRoute[] = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'application-list',
    loadChildren: () => import('./application/application.module').then((m) => m.ApplicationModule),
  },
  {
    path: 'apply-forms',
    loadChildren: () => import('./apply-form/apply-form.module').then((m) => m.ApplyFormModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'job-ad-list',
    loadChildren: () => import('./job-ad/job-ad.module').then((m) => m.JobAdModule),
  },
  {
    path: 'process-generator',
    loadChildren: () => import('./process-generator/process-generator.module').then((m) => m.ProcessGeneratorModule),
  },
  {
    path: 'user-management',
    loadChildren: () => import('./user-management/user-management.module').then((m) => m.UserManagementModule),
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
  },
  {
    path: '**',
    component: JcdNotFoundComponent,
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
