import {PermissionEnum, UserGroupPermissionInterface} from '../../../../../user-management/entities';
import {
  group_admin_idFixture,
  group_recruiterIt_idFixture,
  group_recruiterService_idFixture,
  group_vdEast_idFixture,
  group_vdSouth_idFixture
} from '../../fixture-ids.fixture';

export let organisationGroupPermissionsFixtures: { [idGroup: string]: UserGroupPermissionInterface } = {
  [group_admin_idFixture]: {
    [PermissionEnum.application_attachments]: true,
    [PermissionEnum.application_communication]: true,
    [PermissionEnum.application_create]: true,
    [PermissionEnum.application_delete]: true,
    [PermissionEnum.application_editMetadata]: true,
    [PermissionEnum.application_editStatus]: true,
    [PermissionEnum.application_read]: true,
    [PermissionEnum.applyForm_create]: true,
    [PermissionEnum.applyForm_delete]: true,
    [PermissionEnum.applyForm_edit]: true,
    [PermissionEnum.userGroup_create]: true,
    [PermissionEnum.userGroup_delete]: true,
    [PermissionEnum.userGroup_edit]: true,
    [PermissionEnum.jobAd_create]: true,
    [PermissionEnum.jobAd_edit]: true,
    [PermissionEnum.jobAd_delete]: true,
    [PermissionEnum.process_create]: true,
    [PermissionEnum.process_delete]: true,
    [PermissionEnum.process_edit]: true,
    [PermissionEnum.user_invite]: true,
    [PermissionEnum.user_editPermission]: true,
    [PermissionEnum.user_delete]: true,
    [PermissionEnum.reports_read]: true,
  },
  [group_vdEast_idFixture]: {
    [PermissionEnum.application_attachments]: true,
    [PermissionEnum.application_communication]: true,
    [PermissionEnum.application_create]: true,
    [PermissionEnum.application_delete]: true,
    [PermissionEnum.application_editMetadata]: true,
    [PermissionEnum.application_editStatus]: true,
    [PermissionEnum.application_read]: true,
    [PermissionEnum.applyForm_create]: true,
    [PermissionEnum.applyForm_delete]: true,
    [PermissionEnum.applyForm_edit]: true,
    [PermissionEnum.userGroup_edit]: true,
    [PermissionEnum.jobAd_create]: true,
    [PermissionEnum.jobAd_edit]: true,
    [PermissionEnum.jobAd_delete]: true,
    [PermissionEnum.process_create]: true,
    [PermissionEnum.process_delete]: true,
    [PermissionEnum.process_edit]: true,
    [PermissionEnum.user_invite]: true,
    [PermissionEnum.user_editPermission]: true,
    [PermissionEnum.user_delete]: true,
    [PermissionEnum.reports_read]: true,
  },
  [group_vdSouth_idFixture]: {
    [PermissionEnum.application_attachments]: true,
    [PermissionEnum.application_communication]: true,
    [PermissionEnum.application_create]: true,
    [PermissionEnum.application_delete]: true,
    [PermissionEnum.application_editMetadata]: true,
    [PermissionEnum.application_editStatus]: true,
    [PermissionEnum.application_read]: true,
    [PermissionEnum.applyForm_create]: true,
    [PermissionEnum.applyForm_delete]: true,
    [PermissionEnum.applyForm_edit]: true,
    [PermissionEnum.userGroup_edit]: true,
    [PermissionEnum.jobAd_create]: true,
    [PermissionEnum.jobAd_edit]: true,
    [PermissionEnum.jobAd_delete]: true,
    [PermissionEnum.process_create]: true,
    [PermissionEnum.process_delete]: true,
    [PermissionEnum.process_edit]: true,
    [PermissionEnum.user_invite]: true,
    [PermissionEnum.user_editPermission]: true,
    [PermissionEnum.user_delete]: true,
    [PermissionEnum.reports_read]: true,
  },
  [group_recruiterIt_idFixture]: {
    [PermissionEnum.application_attachments]: true,
    [PermissionEnum.application_communication]: true,
    [PermissionEnum.application_create]: true,
    [PermissionEnum.application_editStatus]: true,
    [PermissionEnum.application_read]: true,
    [PermissionEnum.applyForm_edit]: true,
    [PermissionEnum.jobAd_create]: true,
    [PermissionEnum.jobAd_edit]: true,
    [PermissionEnum.jobAd_delete]: true,
    [PermissionEnum.reports_read]: true,
  },
  [group_recruiterService_idFixture]: {
    [PermissionEnum.application_attachments]: true,
    [PermissionEnum.application_communication]: true,
    [PermissionEnum.application_create]: true,
    [PermissionEnum.application_editStatus]: true,
    [PermissionEnum.application_read]: true,
    [PermissionEnum.applyForm_edit]: true,
    [PermissionEnum.jobAd_create]: true,
    [PermissionEnum.jobAd_edit]: true,
    [PermissionEnum.jobAd_delete]: true,
    [PermissionEnum.reports_read]: true,
  },
};

