import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {NavigationEnd, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {delay, filter} from 'rxjs';


@UntilDestroy()
@Component({
  selector: 'bms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  constructor(
    private $breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
  }


  ngAfterViewInit() {
    this.registerSideNavObservables();
  }


  private registerSideNavObservables(): void {
    this.$breakpointObserver.observe([
      Breakpoints.Small,
      Breakpoints.XSmall,
    ])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe(
        result => {
          if (result.breakpoints[Breakpoints.Small] || result.breakpoints[Breakpoints.XSmall]) {
            this.sidenav.mode = 'over';
            this.sidenav.close();
            return;
          }

          this.sidenav.mode = 'side';
          this.sidenav.open();
        }
      );

    // this closes the side navigation overlay on small devices
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });
  }
}
