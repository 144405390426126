import {OrganisationLevelInterface} from '../../../../../organisation/entities';
import {
  organisationLevel_berlin_idFixture,
  organisationLevel_halle_idFixture,
  organisationLevel_jcd_idFixture,
  organisationLevel_munich_idFixture,
  organisationLevel_munichIt_idFixture,
  organisationLevel_stuttgart_idFixture,
  organisationLevel_stuttgartIt_idFixture,
  organisationLevel_stuttgartService_idFixture,
  organisationLevel_vdEast_idFixture,
  organisationLevel_vdSouth_idFixture
} from '../../fixture-ids.fixture';

export let organisationOrganisationLevelFixture: Array<OrganisationLevelInterface> = [
  {
    id: organisationLevel_jcd_idFixture,
    parentId: null,
    name: 'Jobcluster Deutschland GmbH',
    userHasAccess: true,
  },
  {
    id: organisationLevel_vdSouth_idFixture,
    parentId: organisationLevel_jcd_idFixture,
    name: 'Vertriebsdirektion Süd',
    userHasAccess: true,
  },
  {
    id: organisationLevel_munich_idFixture,
    parentId: organisationLevel_vdSouth_idFixture,
    name: 'München',
    userHasAccess: true,
  },
  {
    id: organisationLevel_munichIt_idFixture,
    parentId: organisationLevel_munich_idFixture,
    name: 'IT',
    userHasAccess: true,
  },
  {
    id: organisationLevel_stuttgart_idFixture,
    parentId: organisationLevel_vdSouth_idFixture,
    name: 'Stuttgart',
    userHasAccess: true,
  },
  {
    id: organisationLevel_stuttgartIt_idFixture,
    parentId: organisationLevel_stuttgart_idFixture,
    name: 'IT',
    userHasAccess: true,
  },
  {
    id: organisationLevel_stuttgartService_idFixture,
    parentId: organisationLevel_stuttgart_idFixture,
    name: 'Service',
    userHasAccess: true,
  },
  {
    id: organisationLevel_vdEast_idFixture,
    parentId: organisationLevel_jcd_idFixture,
    name: 'Vertriebsdirektion Ost',
    userHasAccess: true,
  },
  {
    id: organisationLevel_halle_idFixture,
    parentId: organisationLevel_vdEast_idFixture,
    name: 'Halle',
    userHasAccess: true,
  },
  {
    id: organisationLevel_berlin_idFixture,
    parentId: organisationLevel_vdEast_idFixture,
    name: 'Berlin',
    userHasAccess: true,
  },
];
