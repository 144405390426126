import {UserGroupInterface} from '../../../../../user-management/entities';
import {
  group_admin_idFixture,
  group_recruiterIt_idFixture,
  group_recruiterService_idFixture,
  group_vdEast_idFixture,
  group_vdSouth_idFixture
} from '../../fixture-ids.fixture';

export let organisationGroupsFixture: Array<UserGroupInterface> = [
  {
    id: group_admin_idFixture,
    name: 'Administrator',
    sort: 1,
    isEditable: false,
  },
  {
    id: group_vdEast_idFixture,
    name: 'Vertriebsdirektion Ost',
    sort: 2,
    isEditable: true,
  },
  {
    id: group_vdSouth_idFixture,
    name: 'Vertriebsdirektion Süd',
    sort: 2,
    isEditable: true,
  },
  {
    id: group_recruiterIt_idFixture,
    name: 'Recruiter IT',
    sort: 3,
    isEditable: true,
  },
  {
    id: group_recruiterService_idFixture,
    name: 'Recruiter Service',
    sort: 3,
    isEditable: true,
  },
];
