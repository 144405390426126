import {Injectable} from '@angular/core';
import {
  JcdApiErrorResponse,
  JcdApiService,
  JcdApiSuccessResponse,
  JcdErrorHandlerService, JcdGenericDeleteResponseInterface,
  JcdGenericErrorResponseInterface,
} from 'jcd-shared-components';
import {ApplicationInterface} from '../../application/application-list/application-list.component';
import {SearchTagInterface} from '../layout/search-tags/search-tags.component';
import {
  ApplicationCreateResponseInterface,
  ApplicationGetAllResponseInterface,
  ApplicationReplaceResponseInterface,
  JobAdGetAllResponseInterface,
  JobAdStatusDefinitionGetAllResponseInterface,
  JobAdTypeDefinitionGetAllResponseInterface,
  SearchTagCreateResponseInterface,
  SearchTagGetAllResponseInterface
} from './api-response.interface';


@Injectable({
  providedIn: 'root',
})
export class OrganisationApiService extends JcdApiService {

  public applicationsGetAll(
    organisationId: string,
    successCallback: (response: JcdApiSuccessResponse<ApplicationGetAllResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'organisations/' + organisationId + '/applications',
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback,
    );
  }

  public applicationCreate(
    organisationId: string,
    data:ApplicationInterface,
    successCallback: (response: JcdApiSuccessResponse<ApplicationCreateResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'organisations/' + organisationId + '/applications',
      this.methods.create,
      data,
      successCallback,
      errorCallback,
      completeCallback,
    );
  }


  public applicationReplace(
    organisationId: string,
    applicationId:string,
    data:ApplicationInterface,
    successCallback: (response: JcdApiSuccessResponse<ApplicationReplaceResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'organisations/' + organisationId + '/applications/' + applicationId,
      this.methods.replace,
      data,
      successCallback,
      errorCallback,
      completeCallback,
    );
  }



  public applicationDelete(
      organisationId: string,
      applicationId: string,
      successCallback: (response: JcdApiSuccessResponse<JcdGenericDeleteResponseInterface>) => void,
      errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
      completeCallback?: () => void,
  ): void {
    this.call(
        'organisations/' + organisationId + '/applications/' + applicationId,
        this.methods.delete,
        {},
        successCallback,
        errorCallback,
        completeCallback,
    );
  }






  public searchTagCreate(
    organisationId: string,
    data: SearchTagInterface,
    successCallback: (response: JcdApiSuccessResponse<SearchTagCreateResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'organisations/' + organisationId + '/search-tags',
      this.methods.create,
      data,
      successCallback,
      errorCallback,
      completeCallback,
    );
  }


  public searchTagsGetAll(
    organisationId: string,
    successCallback: (response: JcdApiSuccessResponse<SearchTagGetAllResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'organisations/' + organisationId + '/search-tags',
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback,
    );
  }


  public jobAdsGetAll(
    organisationId: string,
    successCallback: (response: JcdApiSuccessResponse<JobAdGetAllResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'organisations/' + organisationId + '/job-ads',
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback,
    );
  }


  public jobAdsStatusDefinitionGetAll(
    organisationId: string,
    successCallback: (response: JcdApiSuccessResponse<JobAdStatusDefinitionGetAllResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'organisations/' + organisationId + '/job-ads-status-definition',
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback,
    );
  }


  public jobAdsTypeDefinitionGetAll(
    organisationId: string,
    successCallback: (response: JcdApiSuccessResponse<JobAdTypeDefinitionGetAllResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'organisations/' + organisationId + '/job-ads-type-definition',
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback,
    );
  }
}
