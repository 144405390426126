<div>
  <img
      alt="Logo - Bewerbermanagementsystem"
      src="/assets/logo.svg"
  >
</div>
<div>
  <bms-menu-emails></bms-menu-emails>
  <bms-menu-notifications></bms-menu-notifications>
  <bms-menu-user></bms-menu-user>
</div>
