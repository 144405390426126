import{EmailInterface} from '../../../../layout/menu-emails/menu-emails.component';

export let emails: EmailInterface[] = [
  {
    emailId: '4cfcea89-cd61-401b-939c-2e9e0e07c715',
    avatarLink: '',
    firstName: 'Heike-Annegret',
    lastName: 'Müller',
    emailAddress: 'spackenkopf@web.de',
    emailSubject: 'RE: Rückfragen zu Ihrer Bewerbung',
    emailDateTime: '2022-07-12T15:55:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: 'dd73393a-5bd1-41b4-a7b7-eb7da06ba8bc',
    avatarLink: 'https://i.pravatar.cc/150?img=3',
    firstName: 'Ute',
    lastName: 'Pfritzelhuber',
    emailAddress: 'u-pfritzelhuber@mail.de',
    emailSubject: 'Meine Initiativbewerbung vom 10.10.2020',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: 'd9f57684-de6a-47a8-9577-b907a8d8afe7',
    avatarLink: 'https://i.pravatar.cc/150?img=40',
    firstName: 'Ursula',
    lastName: 'Strunzel',
    emailAddress: 'uschi99@freenet.de',
    emailSubject: 'Haben Sie schon meine Bewerbung gelesen?',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: '45bfc596-bab1-4dc7-9c7c-7082570da382',
    avatarLink: '',
    firstName: 'Kevin',
    lastName: 'Neubauer-Müller',
    emailAddress: 'daskev@mail.de',
    emailSubject: 'RE: Anforderung Unterlagen',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: 'cb94e49f-4037-43d5-a309-fbe82172f6b5',
    avatarLink: '',
    firstName: 'Conny',
    lastName: 'Plunder',
    emailAddress: 'conny.plunder@emailversand.de',
    emailSubject: 'Ich möchte mich bewerben, wie geht das?',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: 'bd45d36c-5cb6-4f9d-88fd-3bfd382db574',
    avatarLink: 'https://i.pravatar.cc/150?img=22',
    firstName: 'Franzi',
    lastName: 'Pickelhuber',
    emailAddress: 'jomei@bavaria.de',
    emailSubject: 'Servus wie schauts?',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: 'eeab17b5-c624-4a5f-b3a0-16cc9788a64d',
    avatarLink: '',
    firstName: 'Marvin',
    lastName: 'Rübezahl',
    emailAddress: 'ruckizucki@gmx.de',
    emailSubject: 'RE:  Unterlagen',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: '55fb9afd-ff20-4d7f-918c-134de6ac5cf3',
    avatarLink: 'https://i.pravatar.cc/150?img=13',
    firstName: 'Fred',
    lastName: 'Feuerstein',
    emailAddress: 'yapadapa@do.com',
    emailSubject: 'Bewerbung als Bademeister',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: 'df10b81f-c676-4871-b5ab-5c9e19fd8960',
    avatarLink: 'https://i.pravatar.cc/150?img=12',
    firstName: 'Gisela',
    lastName: 'Gieser',
    emailAddress: 'gg@gmx.de',
    emailSubject: 'Nachfrage zu meiner Bewerbung',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
  {
    emailId: '816171fa-97df-43dd-8a81-f11c58ff443f',
    avatarLink: '',
    firstName: 'Klaus',
    lastName: 'Klabuster',
    emailAddress: 'klausi99@web.de',
    emailSubject: 'Meine Bewerbung',
    emailDateTime: '2019-09-07T15:50:00+00:00',
    emailIsRead: false,
  },
];
