import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class IconRegistryModule {

  images = [
    'resize',
  ];

  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {

    for (const image of this.images) {
      iconRegistry.addSvgIcon(
        image,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${image}.svg`),
      );
    }
  }
}
