import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {JcdApiSuccessResponse} from 'jcd-shared-components';
import {map} from 'rxjs';
import {SearchTagCreateResponseInterface, SearchTagGetAllResponseInterface} from '../../api/api-response.interface';
import {OrganisationApiService} from '../../api/organisation.api.service';

export interface SearchTagInterface {
  id: string,
  name: string,
}

@Component(
  {
    selector: 'bms-search-tags',
    templateUrl: './search-tags.component.html',
    styleUrls: ['./search-tags.component.scss']
  }
)
export class SearchTagsComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];

  tagCtrl = new FormControl<string>('');

  allSearchTags: SearchTagInterface[] = [];

  selectedSearchTags: SearchTagInterface[] = [];

  selectableSearchTags: SearchTagInterface[] = [];

  @Input() getSearchTagsFromParent: string[] = [];

  @Output() setSearchTagsInParent = new EventEmitter<Array<string>>();

  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;


  constructor(
    private organisationApiService: OrganisationApiService,
  ) {
  }


  ngOnInit() {
    this.getData();
    this.prepareSubscribers();
  }


  add(event: MatChipInputEvent): void {
    const value = (event.value || '').toLocaleLowerCase().trim();
    if (value) {
      //check if name already exists in allSearchTags
      const result = this.allSearchTags.some((obj) => {
        return obj.name === value;
      });
      if (!result) {
        this.createNewGlobalSearchTag(value);
      }
    }

    // Clear input value
    event.chipInput!.clear();
    this.tagCtrl.setValue(null);
    this.updateParentSearchTags();
  }


  remove(tag: SearchTagInterface): void {
    //remove tag from selectedSearchTags
    const index = this.selectedSearchTags.indexOf(tag);
    if (index >= 0) {
      this.selectedSearchTags.splice(index, 1);
    }

    // Clear input value
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.updateParentSearchTags();
  }


  selected(event: MatAutocompleteSelectedEvent): void {
    // add tag to selectedSearchTags
    this.selectedSearchTags.push(
      event.option.value
    );

    // Clear input value
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.updateParentSearchTags();
  }


  private updateParentSearchTags() {
    this.setSearchTagsInParent.emit(
      this.selectedSearchTags.map(tag => tag.id)
    );
  }


  private getData(): void {

    const searchTagsGetAllSuccessCallback = (apiResponse: JcdApiSuccessResponse<SearchTagGetAllResponseInterface>): void => {
      this.allSearchTags = apiResponse.payload.sort(
        (a, b) => a.name.localeCompare(b.name)
      );

      this.selectedSearchTags = this.allSearchTags
        .filter(
          find => this.getSearchTagsFromParent.some(
            tag => tag === find.id
          )
        );

      this.selectableSearchTags = this.allSearchTags
        .filter(
          (element: SearchTagInterface) =>
            !this.selectedSearchTags.includes(
              element
            )
        );
    };

    this.organisationApiService.searchTagsGetAll(
      // @toDo Implement organisationId
      'f514206d-e042-4f51-aa8b-1cbbb5149774',
      searchTagsGetAllSuccessCallback
    );
  }


  private createNewGlobalSearchTag(name: string): void {

    const globalSearchTagCreateSuccessCallback = (apiResponse: JcdApiSuccessResponse<SearchTagCreateResponseInterface>): void => {
      this.allSearchTags.push({
        id: apiResponse.payload.id,
        name: name,
      });
      this.selectedSearchTags.push({
        id: apiResponse.payload.id,
        name: name,
      });
      this.updateParentSearchTags();
    };

    this.organisationApiService.searchTagCreate(
      // @toDo Implement organisationId
      'f514206d-e042-4f51-aa8b-1cbbb5149774',
      {name} as SearchTagInterface,
      globalSearchTagCreateSuccessCallback,
    );
  }


  private prepareSubscribers(): void {

    // this updates the autocomplete list on user input
    this.tagCtrl.valueChanges.pipe(
      map(
        (searchValue: SearchTagInterface | string | null) => {

          if (typeof searchValue === 'string') {
            // first we look for the searchValue in ALL search tags.
            let tags = this.allSearchTags.filter(tag => tag.name.includes(searchValue));

            // second, we remove the selected tags from the list.
            return tags.filter(
              tag => !this.selectedSearchTags
                // this reduces the array of tag objects to an array of ids
                .map(
                  selectedTag => selectedTag.id
                )
                .includes(tag.id));
          }

          // only remove the selected tags from the available tags list.
          return this.allSearchTags.filter(
            tag => !this.selectedSearchTags
              // this reduces the array of tag objects to an array of ids
              .map(
                selectedTag => selectedTag.id
              )
              .includes(tag.id)
          );
        }
      ),
    ).subscribe(
      next => {
        this.selectableSearchTags = next;
      }
    );
  }
}
