import {Component, OnInit} from '@angular/core';
import {JcdApiSuccessResponse} from 'jcd-shared-components';
import {UserProfileGetResponseInterface} from '../../api/api-response.interface';
import {ProfileApiService} from '../../api/profile-api.service';


export interface ProfileInterface {
  userId: string;
  avatarLink: string;
  firstName: string;
  lastName: string;
  companyName: string;
}


@Component({
  selector: 'bms-menu-user',
  templateUrl: './menu-user.component.html',
  styleUrls: ['./menu-user.component.scss'],

})
export class MenuUserComponent implements OnInit {
  profileData: null | ProfileInterface = null;

  constructor(
    private userApiService: ProfileApiService
  ) {

  }

  ngOnInit(): void {
   this.getData();
  }

  userLogOff(): void {
    // @todo user logoff
    alert('todo');
  }

  userShowProfile(userId: string | undefined): void {
    // @todo show explicit email
    alert('todo userId: ' + userId);
  }

  private getData(): void {
    const userProfileGetSuccessCallback = (apiResponse: JcdApiSuccessResponse<UserProfileGetResponseInterface>): void => {
      this.profileData = apiResponse.payload;
    };

    this.userApiService.userProfileGet(
      userProfileGetSuccessCallback,
    );
  }

}
