import {
  RecruitingProcessActionInterface,
  RecruitingProcessStepConnectionInterface,
  RecruitingProcessStepInterface
} from '../../../../../../process-generator/process-entities.interface';
import {recruitingProcess_itSpecialist_idFixture} from '../../../fixture-ids.fixture';
import {GenericSearchTagsEnum} from '../../search-tags/search-tags.fixture';
import {recruitingProcessesActionsFixture} from '../recruiting-processes.fixture';

export let processActionFixture: { [key: string]: { [key: string]: Array<RecruitingProcessActionInterface> } } = {
  // IT-Fachkräfte
  [recruitingProcess_itSpecialist_idFixture]: {
    // Neu
    '00000000-0000-4000-8000-000000000001': [
      {
        id: 'b9102b09-8c24-4fab-bffc-d0f629554726',
        parentId: null,
        sourceId: '56c6bd2a-972e-4cb7-92bd-d119abfdd6ee',
        recruitingStepId: '0992ac45-300e-4463-b89f-54438e6ae531',
        // mail
        source: recruitingProcessesActionsFixture.find(element => element.id === '56c6bd2a-972e-4cb7-92bd-d119abfdd6ee')!,
        values: {
          // @formatter:off
          to: [{type: 'paragraph', children: [{text: ''}, {type: 'process-action', id: 'applyForm', key: 'email', children: [{text: ''}]}, {text: ''}]}],
          cc: [{type: 'paragraph', children: [{text: 'mein.name@beispiel.tld'}]}],
          subject: [{type: 'paragraph', children: [{text: 'Ihre Bewerbung auf die Ausschreibung '}, {type: 'process-action', id: 'process', key: 'applicationName', children: [{text: ''}]}, {text: ''}]}],
          message: [{type: 'paragraph', children: [{text: 'Hallo '}, {type: 'process-action', id: 'applyForm', key: 'salutation', children: [{text: ''}]}, {text: ' '}, {type: 'process-action', id: 'applyForm', key: 'firstName', children: [{text: ''}]}, {text: ' '}, {type: 'process-action', id: 'applyForm', key: 'lastName', children: [{text: ''}]}, {text: ','}]}, {type: 'paragraph', children: [{text: 'wir haben Ihre Bewerbung auf die Ausschreibung '}, {type: 'process-action', id: 'process', key: 'applicationName', children: [{text: ''}]}, {text: ' dankend erhalten und werden uns alsbald bei Ihnen melden.'}]}, {type: 'paragraph', children: [{text: 'Mit freundlichen Grüßen'}]}, {type: 'paragraph', children: [{text: ''}, {type: 'process-action', id: 'process', key: 'currentUserFirstName', children: [{text: ''}]}, {text: ' '}, {type: 'process-action', id: 'process', key: 'currentUserLastName', children: [{text: ''}]}, {text: ''}]}],
          // @formatter:on
        },
      },
      {
        id: '89db452a-1d7d-4e9e-bec8-33f539ac6132',
        parentId: 'b9102b09-8c24-4fab-bffc-d0f629554726',
        sourceId: '62c1bcba-486c-44e6-b808-1e2bc6cdcd58',
        recruitingStepId: '0992ac45-300e-4463-b89f-54438e6ae531',
        // ms teams message
        source: recruitingProcessesActionsFixture.find(element => element.id === '62c1bcba-486c-44e6-b808-1e2bc6cdcd58')!,
        values: {
          // @formatter:off
          channel: [{type: 'paragraph', children: [{text: 'Fachabteilung Recruiting'}]}],
          message: [{type: 'paragraph', children: [{text: 'Neue Bewerbung auf Ausschreibung '}, {type: 'process-action', id: 'process', key: 'applicationName', children: [{text: ''}]}, {text: ''}]}],
          // @formatter:on
        },
      },
      {
        id: '982475a6-00ad-4fb0-ad1b-a2e216c9ee0f',
        parentId: '89db452a-1d7d-4e9e-bec8-33f539ac6132',
        sourceId: '5b2c2208-6af0-4749-8253-a831fb9abb66',
        recruitingStepId: '0992ac45-300e-4463-b89f-54438e6ae531',
        // kalendereintrag erstellen
        source: recruitingProcessesActionsFixture.find(element => element.id === '5b2c2208-6af0-4749-8253-a831fb9abb66')!,
        values: {
          // @formatter:off
          datetime: [{type: 'paragraph', children: [{text: new Date((new Date()).setDate((new Date()).getDate() + 7)).toLocaleString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric'}) + ' 12:00'}]}],
          description: [{type: 'paragraph', children: [{text: 'Bewerber anschreiben'}]}],
          // @formatter:on
        },
      },
    ]
  }
};


export let processStepFixture: { [key: string]: Array<RecruitingProcessStepInterface> } = {
  // IT-Fachkräfte
  [recruitingProcess_itSpecialist_idFixture]: [
    {
      id: '00000000-0000-4000-8000-000000000001',
      recruitingProcessId: recruitingProcess_itSpecialist_idFixture,
      name: 'Neu',
      abbreviation: 'NEU',
      backgroundColor: '#dc4734',
      isTerminateProcess: false,
      positionX: 150,
      positionY: 130,
      isStart: true,
      isAllConnection: false,
      searchTags: [GenericSearchTagsEnum.ApplicationNew,],
    },
    {
      id: '00000000-0000-4000-8000-000000000002',
      recruitingProcessId: recruitingProcess_itSpecialist_idFixture,
      name: 'Unterlagen nachgefordert',
      abbreviation: 'UNF',
      backgroundColor: '#faa332',
      isTerminateProcess: false,
      positionX: 220,
      positionY: 290,
      isStart: false,
      isAllConnection: false,
      searchTags: [GenericSearchTagsEnum.ApplicationDocuments,],
    },
    {
      id: '00000000-0000-4000-8000-000000000003',
      recruitingProcessId: recruitingProcess_itSpecialist_idFixture,
      name: 'Eingeladen',
      abbreviation: 'EGL',
      backgroundColor: '#ffe14f',
      isTerminateProcess: false,
      positionX: 150,
      positionY: 430,
      isStart: false,
      isAllConnection: false,
      searchTags: [GenericSearchTagsEnum.ApplicationInvited,],
    },
    {
      id: '00000000-0000-4000-8000-000000000004',
      recruitingProcessId: recruitingProcess_itSpecialist_idFixture,
      name: 'Eingestellt',
      abbreviation: 'EGS',
      backgroundColor: '#859900',
      isTerminateProcess: false,
      positionX: 80,
      positionY: 570,
      isStart: false,
      isAllConnection: false,
      searchTags: [GenericSearchTagsEnum.ApplicationHired,],
    },
    {
      id: '00000000-0000-4000-8000-000000000005',
      recruitingProcessId: recruitingProcess_itSpecialist_idFixture,
      name: 'Abgelehnt',
      abbreviation: 'ABG',
      backgroundColor: '#0d6efd',
      isTerminateProcess: false,
      positionX: 220,
      positionY: 570,
      isStart: false,
      isAllConnection: true,
      searchTags: [GenericSearchTagsEnum.ApplicationRejected,],
    },
  ],


  // Ärzt*in
  '6da7dcff-e141-42bc-9c53-fb94897c7b00': [
    {
      id: '00000000-0000-4000-8000-100000000001',
      recruitingProcessId: '6da7dcff-e141-42bc-9c53-fb94897c7b00',
      name: 'Neu',
      abbreviation: 'NEU',
      backgroundColor: '#dc4734',
      isTerminateProcess: false,
      positionX: 150,
      positionY: 130,
      isStart: true,
      isAllConnection: false,
      searchTags: [GenericSearchTagsEnum.ApplicationNew,],
    },
    {
      id: '00000000-0000-4000-8000-100000000002',
      recruitingProcessId: '6da7dcff-e141-42bc-9c53-fb94897c7b00',
      name: 'Unterlagen nachgefordert',
      abbreviation: 'UNF',
      backgroundColor: '#faa332',
      isTerminateProcess: false,
      positionX: 220,
      positionY: 290,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-100000000003',
      recruitingProcessId: '6da7dcff-e141-42bc-9c53-fb94897c7b00',
      name: 'Eingeladen',
      abbreviation: 'EGL',
      backgroundColor: '#ffe14f',
      isTerminateProcess: false,
      positionX: 150,
      positionY: 430,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-100000000004',
      recruitingProcessId: '6da7dcff-e141-42bc-9c53-fb94897c7b00',
      name: 'Eingestellt',
      abbreviation: 'EGS',
      backgroundColor: '#859900',
      isTerminateProcess: false,
      positionX: 80,
      positionY: 570,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-100000000005',
      recruitingProcessId: '6da7dcff-e141-42bc-9c53-fb94897c7b00',
      name: 'Abgelehnt',
      abbreviation: 'ABG',
      backgroundColor: '#0d6efd',
      isTerminateProcess: false,
      positionX: 220,
      positionY: 570,
      isStart: false,
      isAllConnection: true,
      searchTags: [],
    },
  ],


  // Erzieher*in
  '5e384b44-6d16-4123-afb3-73063cd7cf13': [
    {
      id: '00000000-0000-4000-8000-200000000001',
      recruitingProcessId: '5e384b44-6d16-4123-afb3-73063cd7cf13',
      name: 'Neu',
      abbreviation: 'NEU',
      backgroundColor: '#dc4734',
      isTerminateProcess: false,
      positionX: 150,
      positionY: 130,
      isStart: true,
      isAllConnection: false,
      searchTags: [GenericSearchTagsEnum.ApplicationNew,],
    },
    {
      id: '00000000-0000-4000-8000-200000000002',
      recruitingProcessId: '5e384b44-6d16-4123-afb3-73063cd7cf13',
      name: 'Unterlagen nachgefordert',
      abbreviation: 'UNF',
      backgroundColor: '#faa332',
      isTerminateProcess: false,
      positionX: 220,
      positionY: 290,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-200000000003',
      recruitingProcessId: '5e384b44-6d16-4123-afb3-73063cd7cf13',
      name: 'Eingeladen',
      abbreviation: 'EGL',
      backgroundColor: '#ffe14f',
      isTerminateProcess: false,
      positionX: 150,
      positionY: 430,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-200000000004',
      recruitingProcessId: '5e384b44-6d16-4123-afb3-73063cd7cf13',
      name: 'Eingestellt',
      abbreviation: 'EGS',
      backgroundColor: '#859900',
      isTerminateProcess: false,
      positionX: 80,
      positionY: 570,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-200000000005',
      recruitingProcessId: '5e384b44-6d16-4123-afb3-73063cd7cf13',
      name: 'Abgelehnt',
      abbreviation: 'ABG',
      backgroundColor: '#0d6efd',
      isTerminateProcess: false,
      positionX: 220,
      positionY: 570,
      isStart: false,
      isAllConnection: true,
      searchTags: [],
    },
  ],


  // Krankenpfleger*in
  '048b8d0a-741a-411e-be04-db563c8e5c38': [
    {
      id: '00000000-0000-4000-8000-300000000001',
      recruitingProcessId: '048b8d0a-741a-411e-be04-db563c8e5c38',
      name: 'Neu',
      abbreviation: 'NEU',
      backgroundColor: '#dc4734',
      isTerminateProcess: false,
      positionX: 150,
      positionY: 130,
      isStart: true,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-300000000002',
      recruitingProcessId: '048b8d0a-741a-411e-be04-db563c8e5c38',
      name: 'Unterlagen nachgefordert',
      abbreviation: 'UNF',
      backgroundColor: '#faa332',
      isTerminateProcess: false,
      positionX: 220,
      positionY: 290,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-300000000003',
      recruitingProcessId: '048b8d0a-741a-411e-be04-db563c8e5c38',
      name: 'Eingeladen',
      abbreviation: 'EGL',
      backgroundColor: '#ffe14f',
      isTerminateProcess: false,
      positionX: 150,
      positionY: 430,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-300000000004',
      recruitingProcessId: '048b8d0a-741a-411e-be04-db563c8e5c38',
      name: 'Eingestellt',
      abbreviation: 'EGS',
      backgroundColor: '#859900',
      isTerminateProcess: false,
      positionX: 80,
      positionY: 570,
      isStart: false,
      isAllConnection: false,
      searchTags: [],
    },
    {
      id: '00000000-0000-4000-8000-300000000005',
      recruitingProcessId: '048b8d0a-741a-411e-be04-db563c8e5c38',
      name: 'Abgelehnt',
      abbreviation: 'ABG',
      backgroundColor: '#0d6efd',
      isTerminateProcess: false,
      positionX: 220,
      positionY: 570,
      isStart: false,
      isAllConnection: true,
      searchTags: [],
    },
  ],
};


export let processStepConnectionsFixture: { [key: string]: Array<RecruitingProcessStepConnectionInterface> } = {
  // IT-Fachkraft
  [recruitingProcess_itSpecialist_idFixture]: [
    {
      id: '83009c76-54fd-49fc-a222-0d9ae06eb739',
      startId: '00000000-0000-4000-8000-000000000001',
      targetId: '00000000-0000-4000-8000-000000000002',
    },
    {
      id: '9e390c56-da4a-4ddc-9e9d-640459b2acc7',
      startId: '00000000-0000-4000-8000-000000000002',
      targetId: '00000000-0000-4000-8000-000000000003',
    },
    {
      id: '9e390c56-da4a-4ddc-9e9d-640459b2fff0',
      startId: '00000000-0000-4000-8000-000000000002',
      targetId: '00000000-0000-4000-8000-000000000002',
    },
    {
      id: '314e2271-49c3-4290-8f66-ec62b81442cd',
      startId: '00000000-0000-4000-8000-000000000001',
      targetId: '00000000-0000-4000-8000-000000000003',
    },
    {
      id: '0c04d94c-d8e2-485c-a05c-32949b81890d',
      startId: '00000000-0000-4000-8000-000000000003',
      targetId: '00000000-0000-4000-8000-000000000004',
    },
    {
      id: '7d77957a-ca3f-49f5-9105-2b7bd1ed89eb',
      startId: '00000000-0000-4000-8000-000000000003',
      targetId: '00000000-0000-4000-8000-000000000005',
    },
  ],


  // Ärzt*in
  '6da7dcff-e141-42bc-9c53-fb94897c7b00': [
    {
      id: '71f7fda7-45cb-4d6b-9b5f-15e33eef4717',
      startId: '00000000-0000-4000-8000-100000000001',
      targetId: '00000000-0000-4000-8000-100000000002',
    },
    {
      id: 'ee2bbcf6-07c2-45e4-8f59-5275116ab815',
      startId: '00000000-0000-4000-8000-100000000002',
      targetId: '00000000-0000-4000-8000-100000000003',
    },
    {
      id: 'ee2bbcf6-07c2-45e4-8f59-5275116afff1',
      startId: '00000000-0000-4000-8000-100000000002',
      targetId: '00000000-0000-4000-8000-100000000002',
    },
    {
      id: '2a866e81-eb8f-4363-a775-bf142546343a',
      startId: '00000000-0000-4000-8000-100000000001',
      targetId: '00000000-0000-4000-8000-100000000003',
    },
    {
      id: '181048fc-e488-4e54-8fa1-4f0d1ab9e4e5',
      startId: '00000000-0000-4000-8000-100000000003',
      targetId: '00000000-0000-4000-8000-100000000004',
    },
    {
      id: '18e57ab9-c8e4-4b08-99f5-2e68258de873',
      startId: '00000000-0000-4000-8000-100000000003',
      targetId: '00000000-0000-4000-8000-100000000005',
    },
  ],


  // Erzieher*in
  '5e384b44-6d16-4123-afb3-73063cd7cf13': [
    {
      id: 'f707fd01-cd3c-4d59-a9d5-05355f1491dc',
      startId: '00000000-0000-4000-8000-200000000001',
      targetId: '00000000-0000-4000-8000-200000000002',
    },
    {
      id: 'de166cce-3be4-4bfd-8292-95e9bdad8080',
      startId: '00000000-0000-4000-8000-200000000002',
      targetId: '00000000-0000-4000-8000-200000000003',
    },
    {
      id: 'de166cce-3be4-4bfd-8292-95e9bdadfff2',
      startId: '00000000-0000-4000-8000-200000000002',
      targetId: '00000000-0000-4000-8000-200000000002',
    },
    {
      id: '8f4287e8-4c37-4c39-9119-6e60b2a6d0d7',
      startId: '00000000-0000-4000-8000-200000000001',
      targetId: '00000000-0000-4000-8000-200000000003',
    },
    {
      id: '161f98dc-e230-4580-a30e-01779e7178fe',
      startId: '00000000-0000-4000-8000-200000000003',
      targetId: '00000000-0000-4000-8000-200000000004',
    },
    {
      id: '1c15edfd-6284-4c5e-a334-7ac0fd726cd9',
      startId: '00000000-0000-4000-8000-200000000003',
      targetId: '00000000-0000-4000-8000-200000000005',
    },
  ],


  // Krankenpfleger*in
  '048b8d0a-741a-411e-be04-db563c8e5c38': [
    {
      id: '59f1224d-7b9b-4723-92b8-687e26244ca4',
      startId: '00000000-0000-4000-8000-300000000001',
      targetId: '00000000-0000-4000-8000-300000000002',
    },
    {
      id: '83c4bd36-0d80-4c14-b274-96b61555f701',
      startId: '00000000-0000-4000-8000-300000000002',
      targetId: '00000000-0000-4000-8000-300000000003',
    },
    {
      id: '83c4bd36-0d80-4c14-b274-96b61555fff3',
      startId: '00000000-0000-4000-8000-300000000002',
      targetId: '00000000-0000-4000-8000-300000000002',
    },
    {
      id: '9c52d578-2a8b-4733-a3f3-c05ea4f517f0',
      startId: '00000000-0000-4000-8000-300000000001',
      targetId: '00000000-0000-4000-8000-300000000003',
    },
    {
      id: '2a85df77-320e-4a2e-97b9-55a89541aeee',
      startId: '00000000-0000-4000-8000-300000000003',
      targetId: '00000000-0000-4000-8000-300000000004',
    },
    {
      id: '07822311-3f2b-4409-a130-d6246ff94286',
      startId: '00000000-0000-4000-8000-300000000003',
      targetId: '00000000-0000-4000-8000-300000000005',
    },
  ],
};
