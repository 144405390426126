import {Component, HostListener, OnInit} from '@angular/core';
import {JcdApiSuccessResponse} from 'jcd-shared-components';
import {UserEmailGetAllResponseInterface} from '../../api/api-response.interface';
import {ProfileApiService} from '../../api/profile-api.service';


// @toDo eventuell durch User-Object ersetzen

export interface EmailInterface {
  emailId: string;
  avatarLink: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  emailSubject: string;
  emailDateTime: string;
  emailIsRead: boolean;
}

@Component({
  selector: 'bms-menu-emails',
  templateUrl: './menu-emails.component.html',
  styleUrls: ['./menu-emails.component.scss'],
})
export class MenuEmailsComponent implements OnInit {
  counterEmails: number = 0;
  emailData: EmailInterface[] = [];
  emailDisplayData: EmailInterface[] = [];


  constructor(
    private userApiService: ProfileApiService
  ) {
  }

  ngOnInit(): void {
    this.getData();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.emailDisplayData = this.emailData.slice(0, Math.round(window.innerHeight / 180));
  }

  getData(): void {
    const userEmailGetAllSuccessCallback = (apiResponse: JcdApiSuccessResponse<UserEmailGetAllResponseInterface>): void => {
      this.counterEmails = apiResponse.payload.length;
      this.emailData = apiResponse.payload;
      this.emailDisplayData = this.emailData.slice(0, Math.round(window.innerHeight / 180));
    };
    this.userApiService.userEmailGetAll(
      {filter: 'unread', limit: 10},
      userEmailGetAllSuccessCallback,
    );
  }


  openAllEmailDetails(): void {
    // @todo show all emails
    alert('todo');
  }


  openEmailDetails(mailId: string): void {
    // @todo show explicit email
    alert('todo mailId: ' + mailId);
  }


  public getDateDisplay(dateToCheck: string | undefined): string {
    if (dateToCheck !== undefined) {
      if (new Date(dateToCheck).isToday()) {
        return 'Heute';
      }
      return new Date(dateToCheck).toLocaleDateString(undefined, {day: '2-digit', month: '2-digit', year: 'numeric'});
    }
    return '';
  }
}
