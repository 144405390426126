import {NotificationInterface} from '../../../../layout/menu-notifications/menu-notifications.component';

export let notifications: NotificationInterface[] = [

  {
    notificationId: '0dee6d4d-0380-4665-a92f-61497e88bcec',
    avatarLink: '',
    firstName: 'Heike',
    lastName: 'Müller',
    notificationSubject: 'Statusänderung',
    appliedAs: 'Finanzbuchhalter',
    appliedCompany: 'Jobluster Deutschland GmbH',
    notificationDateTime: '2022-07-12T15:53:00+00:00',
    notificationIsRead:false,
  },
  {
    notificationId: 'b36c3b3b-e0cc-45f0-b61e-277ac61d97be',
    avatarLink: 'https://i.pravatar.cc/150?img=59',
    firstName: 'Klaus',
    lastName: 'Pfritzelhuber',
    notificationSubject: 'Eingeladen',
    appliedAs: 'Finanzbuchhalter',
    appliedCompany: 'Jobluster Deutschland GmbH',
    notificationDateTime: '2019-09-07T15:50:00+00:00',
    notificationIsRead:true,
  },
  {
    notificationId: '649353c4-590d-49e5-b05f-16b7ab641c38',
    avatarLink: 'https://i.pravatar.cc/150?img=65',
    firstName: 'Ursula',
    lastName: 'Strunzel',
    notificationSubject: 'Abgesagt',
    appliedAs: 'Finanzbuchhalter',
    appliedCompany: 'Jobluster Deutschland GmbH',
    notificationDateTime: '2019-09-07T15:50:00+00:00',
    notificationIsRead:false,
  },
  {
    notificationId: '65263ba4-cb89-4fa9-a80e-c9b3f9d9eeb6',
    avatarLink: '',
    firstName: 'Kevin',
    lastName: 'Neubauer',
    notificationSubject: 'Unterlagen nachgefordert',
    appliedAs: 'Finanzbuchhalter',
    appliedCompany: 'Jobluster Deutschland GmbH',
    notificationDateTime: '2019-09-07T15:50:00+00:00',
    notificationIsRead:true,
  },
  {
    notificationId: '34c26f3e-ad96-4fc7-a3ad-47f599a37d5a',
    avatarLink: 'https://i.pravatar.cc/150?img=22',
    firstName: 'Conny',
    lastName: 'Plunder',
    notificationSubject: 'Eingeladen',
    appliedAs: 'Finanzbuchhalter',
    appliedCompany: 'Jobluster Deutschland GmbH',
    notificationDateTime: '2019-09-07T15:50:00+00:00',
    notificationIsRead:false,
  },
  {
    notificationId: 'cd831b5a-979b-49dc-ae9d-db60b879dc2b',
    avatarLink: '',
    firstName: 'Marvin',
    lastName: 'Rubezahl',
    notificationSubject: 'Eingestellt',
    appliedAs: 'Finanzbuchhalter',
    appliedCompany: 'Jobluster Deutschland GmbH',
    notificationDateTime: '2019-09-07T15:50:00+00:00',
    notificationIsRead:false,
  },
];
