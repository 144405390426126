<div class="sidenav-wrapper">

  <a class="menu-button"
     mat-button
     routerLink="/dashboard"
     routerLinkActive="active-link"
  >
    <mat-icon>home</mat-icon>
    <span>Dashboard</span> </a>

  <a class="menu-button"
     mat-button
     routerLink="/job-ad-list"
     routerLinkActive="active-link"
  >
    <mat-icon>assignment</mat-icon>
    <span>Ausschreibungen</span> </a>

  <a class="menu-button"
     mat-button
     routerLink="/application-list"
     routerLinkActive="active-link"
  >
    <mat-icon>person</mat-icon>
    <span>Bewerbungen</span> </a>

  <a class="menu-button"
     mat-button
     routerLink="/apply-forms"
     routerLinkActive="active-link"
  >
    <mat-icon>contact_mail</mat-icon>
    <span>Bewerbungsformulare</span> </a>

  <a class="menu-button"
     mat-button
     routerLink="/process-generator"
     routerLinkActive="active-link"
  >
    <mat-icon>account_tree</mat-icon>
    <span>Prozessgenerator</span> </a>


  <a (click)="toggleMenu(routeParentMenuNameEnum.userManagement)"
     class="menu-button has-submenu"
     mat-button
  >
    <mat-icon>admin_panel_settings</mat-icon>
    <div fxFlex>Benutzerverwaltung</div>
    <mat-icon [class]="{'expanded': routeParentMenuName[routeParentMenuNameEnum.userManagement]}"
              class="expandable"
    >
      chevron_right
    </mat-icon>
  </a>

  <div *ngIf="routeParentMenuName[routeParentMenuNameEnum.userManagement]">
    <a class="menu-button padding-left"
       mat-button
       routerLink="/user-management/users-list"
       routerLinkActive="active-link"
    >
      <mat-icon>group</mat-icon>
      <span>Benutzer</span> </a>

    <a class="menu-button padding-left"
       mat-button
       routerLink="/user-management/groups-list"
       routerLinkActive="active-link"
    >
      <mat-icon>lock_open</mat-icon>
      <span>Benutzergruppen</span> </a>

  </div>

  <mat-divider></mat-divider>

  <a class="menu-button"
     mat-button
  >
    <mat-icon>construction</mat-icon>
    <span>Bewerberakte</span> </a>

  <a class="menu-button"
     mat-button
  >
    <mat-icon>construction</mat-icon>
    <span>Bewerberpool</span> </a>


  <a class="menu-button"
     mat-button
     routerLink="/reports"
     routerLinkActive="active-link"
  >
    <mat-icon>construction</mat-icon>
    <span>Berichte</span> </a>
</div>
