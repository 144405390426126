import {ProfileInterface} from '../../../../layout/menu-user/menu-user.component';

export let profile: ProfileInterface =
  {
    userId: 'baf3732c-3529-4648-9ad5-b33661ac3bbf',
    avatarLink: '',
    firstName: 'Klaas',
    lastName: 'Clever',
    companyName: 'Jobcluster Deutschland GmbH',
  }


