<button [matMenuTriggerFor]="emailMenu"
        mat-icon-button
>
  <mat-icon
      [matBadgeHidden]="counterEmails <= 0"
      [matBadge]="counterEmails < 9 ? counterEmails : '9+'"
      matBadgeColor="warn"
  >email
  </mat-icon>
</button>

<mat-menu #emailMenu
          class="bms-custom-user-menu"
>
  <div (click)="openAllEmailDetails()"
       class="menu-head"
       fxLayout="row"
       fxLayoutAlign="end center"
  >
    <div>Alle Emails anzeigen
      <mat-icon inline>view_list</mat-icon>
    </div>
  </div>

  <button (click)="openEmailDetails(email.emailId)"
          *ngFor="let email of emailDisplayData"
          class="menu-item item-email"
          fxLayout="column"
          fxLayoutAlign="start stretch"
          mat-menu-item
  >
    <div fxLayout="column"
         fxLayoutAlign="start start"
    >
      <div fxLayout="row"
           fxLayoutAlign="center center"
      >
        <jcd-avatar-photo [firstName]=email.firstName
                          [lastName]=email.lastName
                          [photoUrl]=email.avatarLink
        ></jcd-avatar-photo>

        <div class="text-user"
             fxLayout="column"
             fxLayoutAlign="center start"
        >
          <div>{{email.firstName}} {{email.lastName}}</div>
          <div>{{email.emailAddress}}</div>
        </div>
      </div>


      <div class="text-sub">{{email.emailSubject}}</div>
    </div>

    <div class="text-sub"
         fxLayout="row"
         fxLayoutAlign="end stretch"
    >
      <div>
        <mat-icon inline>insert_invitation</mat-icon>
        {{getDateDisplay(email.emailDateTime)}}
      </div>
      <div>
        <mat-icon inline>access_time</mat-icon>
        {{email.emailDateTime | date:'HH:mm'}}</div>
    </div>
  </button>
</mat-menu>
