import {Descendant} from 'slate';

export interface RecruitingProcessInterface {
  id: string;
  applyFormId: string;
  name: string;
}


export interface RecruitingProcessStepInterface {
  id: string;
  recruitingProcessId: string;
  name: string;
  abbreviation: string;
  backgroundColor: string;
  isTerminateProcess: boolean;
  positionX: number;
  positionY: number;
  isStart: boolean;
  isAllConnection: boolean;
  searchTags: Array<string>;
}

export interface RecruitingProcessStepConnectionInterface {
  id: string;
  startId: string;
  targetId: string;
}

export interface RecruitingProcessActionSourceInterface {
  id: string;
  name: string;
  iconUrl: string;
  description: string;
  inputActionParameters?: Array<RecruitingProcessActionInputParameterInterface>;
  outputActionParameters?: Array<RecruitingProcessActionOutputParameterInterface>;
}

export interface RecruitingProcessActionInterface {
  id: string;
  // the process action that is running before this action
  parentId: string | null;
  // the process action this action inherits input/output parameters from
  sourceId: string;
  recruitingStepId: string;
  // frontend only. true if the action card is expanded, otherwise false
  isExpanded?: boolean;
  source: RecruitingProcessActionSourceInterface;
  values: {
    [inputParameterKey: string]: Descendant[];
  };
}

export interface RecruitingProcessActionOutputParameterInterface {
  key: string;
  name: string;
  sample?: string;
}

export interface RecruitingProcessActionInputParameterInterface {
  key: string;
  name: string;
  helpText?: string;
  type: RecruitingProcessActionInputParameterTypeEnum;
  dynamicValue?: unknown;
  choices?: Array<string | RecruitingProcessActionInputParameterChoicesInterface>,
  isRequired: boolean;
}

export enum RecruitingProcessActionInputParameterTypeEnum {
  // single line input
  string = 'string',
  // text area input
  text = 'text',
  email = 'email',
  integer = 'integer',
  number = 'number',
  boolean = 'boolean',
  datetime = 'datetime',
  file = 'file',
  password = 'password',
  select = 'select',
}


export interface RecruitingProcessActionInputParameterChoicesInterface {
  value: string,
  name: string,
  sample?: string,
}
