import {
  ApplyFormFieldInterface,
  ApplyFormInterface,
  ApplyFormPoolFieldInterface
} from '../../../../../apply-form/apply-form-entities.interface';
import {applyForm_itSpecialist_idFixture} from '../../fixture-ids.fixture';

export let applyFormPoolFieldFixture: Array<ApplyFormPoolFieldInterface> = [
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Vorname',
    nameApi: 'firstName',
    settings: {
      placeholder: 'Geben Sie Ihren Vornamen an',
    },
    isEditable: false,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Nachname',
    nameApi: 'lastName',
    settings: {
      placeholder: 'Geben Sie Ihren Nachnamen an',
    },
    isEditable: false,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'E-Mail Adresse',
    nameApi: 'email',
    settings: {
      placeholder: 'Geben Sie Ihre E-Mail-Adresse an',
      typeValidation: 'email',
    },

    isEditable: false,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textarea',
    nameExternal: 'Bemerkung',
    nameApi: 'comment',
    settings: {
      placeholder: 'Geben Sie einen Bemerkungstext an',
      maxlength: 500,
      minlength: 10
    },
    isEditable: true,
  },
];


export let applyFormFieldFixture: Array<ApplyFormFieldInterface> = [
  // IT-Fachkraft Formular
  {
    id: self.crypto.randomUUID(),
    type: 'header',
    nameExternal: 'Ihre Bewerbung',
    settings: {
      headerType: 'h2',
    },
    isMandatory: false,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 0,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'paragraph',
    settings: {
      content: '<p>Bitte geben Sie im Folgenden Ihre Daten für die Bewerbungsakte ein.</p><p><strong>Bitte achten Sie auf die korrekte Schreibweise Ihrer Kontaktdaten!</strong></p>',
    },
    isMandatory: false,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 1,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'select',
    nameExternal: 'Anrede',
    nameApi: 'salutation',
    settings: {
      placeholder: 'Geben Sie eine gewünschte Anrede an',
      options: [
        {
          key: 'male',
          value: 'Herr'
        },
        {
          key: 'female',
          value: 'Frau'
        },
        {
          key: 'diverse',
          value: 'Herr/Frau'
        }
      ]
    },
    isMandatory: true,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 2,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Vorname',
    nameApi: 'firstName',
    settings: {
      placeholder: 'Geben Sie Ihren Vornamen an',
    },
    isMandatory: true,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 3,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Nachname',
    nameApi: 'lastName',
    settings: {
      placeholder: 'Geben Sie Ihren Nachnamen an',
    },
    isMandatory: true,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 4,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Telefonnummer',
    nameApi: 'phoneNumber',
    settings: {
      placeholder: 'Geben Sie Ihre Telefonnummer an',
      typeValidation: 'phone',
    },
    isMandatory: false,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 5,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'E-Mail Adresse',
    nameApi: 'email',
    settings: {
      placeholder: 'Geben Sie Ihre E-Mail-Adresse an',
      typeValidation: 'email',
    },
    isMandatory: true,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 6,
  },

  {
    id: self.crypto.randomUUID(),
    type: 'divider',
    isMandatory: false,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 7,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'datepicker',
    nameExternal: 'Frühestmöglicher Einstieg',
    nameApi: 'occupationBegin',
    settings: {
      placeholder: 'Datum wählen',
    },
    isMandatory: false,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 8,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Gehaltsvorstellung in EUR',
    nameApi: 'salary',
    settings: {
      placeholder: 'Geben Sie Ihr Wunschgehalt an',
      typeValidation: 'currency',
    },

    isMandatory: false,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 9,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'radio',
    nameExternal: 'Wie haben Sie von uns erfahren?',
    nameApi: 'reference',
    settings: {
      options: [
        {
          'key': 'tv',
          'value': 'Fernsehen'
        },
        {
          'key': 'zeitung',
          'value': 'Zeitung'
        },
        {
          'key': 'internet',
          'value': 'Internet'
        },
        {
          'key': 'funk',
          'value': 'Funk'
        }
      ],
      value: ['internet'],
    },
    isMandatory: false,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 10,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'select',
    nameApi: 'interestedAreas',
    nameExternal: 'Interessensbereiche',
    settings: {
      placeholder: 'Bitte wählen Sie aus',
      isMulti: true,
      options: [
        {
          key: 'technik',
          value: 'Technik'
        },
        {
          key: 'verwaltung',
          value: 'Verwaltung'
        },
        {
          key: 'service',
          value: 'Service'
        }
      ],
      value: ['verwaltung',],
    },
    isMandatory: true,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 11,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'checkbox',
    nameExternal: 'Verwendung Ihrer Daten',
    nameApi: 'dataUsage',
    settings: {
      options: [
        {
          key: 'otherCompanies',
          value: 'Daten dürfen an Unternehmenstöchter übermittelt werden'
        },
        {
          key: 'longTime',
          value: 'Daten dürfen länger als 3 Monate aufbewahrt werden'
        }
      ],
      value: ['long_time',]
    },
    isMandatory: false,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 12,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'files',
    nameExternal: 'Dateiupload',
    nameApi: 'files',
    settings: {
      value: ['https://www.jobcluster.de/data/karriereportal.pdf'],
      fileTypes: ['pdf', 'image']
    },
    isMandatory: true,
    applyFormId: applyForm_itSpecialist_idFixture,
    index: 13,
  },

  // Ärzt*in Formular
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Vorname',
    nameApi: 'firstName',
    settings: {
      placeholder: 'Geben Sie Ihren Vornamen an',
    },
    isMandatory: true,
    applyFormId: 'ce93664b-c0a4-4e51-829e-aaecab173a31',
    index: 0,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Nachname',
    nameApi: 'lastName',
    settings: {
      placeholder: 'Geben Sie Ihren Nachnamen an',
    },
    isMandatory: true,
    applyFormId: 'ce93664b-c0a4-4e51-829e-aaecab173a31',
    index: 1,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'E-Mail Adresse',
    nameApi: 'email',
    settings: {
      placeholder: 'Geben Sie Ihre E-Mail-Adresse an',
      typeValidation: 'email',
    },
    isMandatory: true,
    applyFormId: 'ce93664b-c0a4-4e51-829e-aaecab173a31',
    index: 2,
  },

  // Erzieher*in Formular
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Vorname',
    nameApi: 'firstName',
    settings: {
      placeholder: 'Geben Sie Ihren Vornamen an',
    },
    isMandatory: true,
    applyFormId: '60b3603f-2d9e-4f7a-9d88-41a188b5498c',
    index: 0,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Nachname',
    nameApi: 'lastName',
    settings: {
      placeholder: 'Geben Sie Ihren Nachnamen an',
    },
    isMandatory: true,
    applyFormId: '60b3603f-2d9e-4f7a-9d88-41a188b5498c',
    index: 1,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'E-Mail Adresse',
    nameApi: 'email',
    settings: {
      placeholder: 'Geben Sie Ihre E-Mail-Adresse an',
      typeValidation: 'email',
    },
    isMandatory: true,
    applyFormId: '60b3603f-2d9e-4f7a-9d88-41a188b5498c',
    index: 2,
  },

  // Krankenpfleger*in Formular
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Vorname',
    nameApi: 'firstName',
    settings: {
      placeholder: 'Geben Sie Ihren Vornamen an',
    },
    isMandatory: true,
    applyFormId: '175d3d00-3986-4d3c-a795-e869255138e5',
    index: 0,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'Nachname',
    nameApi: 'lastName',
    settings: {
      placeholder: 'Geben Sie Ihren Nachnamen an',
    },
    isMandatory: true,
    applyFormId: '175d3d00-3986-4d3c-a795-e869255138e5',
    index: 1,
  },
  {
    id: self.crypto.randomUUID(),
    type: 'textbox',
    nameExternal: 'E-Mail Adresse',
    nameApi: 'email',
    settings: {
      placeholder: 'Geben Sie Ihre E-Mail-Adresse an',
      typeValidation: 'email',
    },
    isMandatory: true,
    applyFormId: '175d3d00-3986-4d3c-a795-e869255138e5',
    index: 2,
  },
];


export let applyFormFixture: Array<ApplyFormInterface> = [
  {
    id: applyForm_itSpecialist_idFixture,
    title: 'IT-Fachkraft Formular',
    description: 'Bewerbungsformular für ITler',
    formFields: applyFormFieldFixture.filter(field => field.applyFormId === applyForm_itSpecialist_idFixture)
  },
  {
    id: 'ce93664b-c0a4-4e51-829e-aaecab173a31',
    title: 'Ärzt*in Formular',
    description: 'Bewebungsformular für Ärzt:innen',
    formFields: applyFormFieldFixture.filter(field => field.applyFormId === 'ce93664b-c0a4-4e51-829e-aaecab173a31')
  },
  {
    id: '60b3603f-2d9e-4f7a-9d88-41a188b5498c',
    title: 'Erzieher*in Formular',
    description: 'Bewerbungsformular für Erzieher:innen',
    formFields: applyFormFieldFixture.filter(field => field.applyFormId === '60b3603f-2d9e-4f7a-9d88-41a188b5498c')
  },
  {
    id: '175d3d00-3986-4d3c-a795-e869255138e5',
    title: 'Krankenpfleger*in Formular',
    formFields: applyFormFieldFixture.filter(field => field.applyFormId === '175d3d00-3986-4d3c-a795-e869255138e5')
  },
]


