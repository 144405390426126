import {FilterInterface} from '../../../../../job-ad/job-ad-list/job-ad-list.component';


export enum JobAdsTypeDefinitionEnum {
  Vollzeit = 'ccf678de-1d26-4c3e-a652-1d66501c24fe',
  Teilzeit = '645a7583-810c-4a8f-9a11-a4456a7a92d9',
  Geringfuegig = 'b25225eb-4c09-4927-9041-afad28763bb4',
  Ausbildung = 'f25d4008-2a5e-434d-a8ea-f97260ece3a5',
}


export let jobAdsTypeDefinition: FilterInterface[] = [
  {
    filterType: 'jobAdType',
    displayMenu: 'Beschäftigungsart',
    displayStyle: '',
    filterData: [
      {
        id: JobAdsTypeDefinitionEnum.Vollzeit,
        filterIsActive: false,
        filterParameters: '',
        displayColor: '#cdeecd',
        displayLong: 'Vollzeit',
        displayShort: 'VZ',
        displayTooltip: 'Vollzeitstelle 40h / Woche',
      },
      {
        id: JobAdsTypeDefinitionEnum.Teilzeit,
        filterIsActive: false,
        filterParameters: '',
        displayColor: '#ceddec',
        displayLong: 'Teilzeit',
        displayShort: 'TZ',
        displayTooltip: 'Teilzeitstelle 20h / Woche',
      },
      {
        id: JobAdsTypeDefinitionEnum.Geringfuegig,
        filterIsActive: false,
        filterParameters: '',
        displayColor: '#edfcde',
        displayLong: 'Geringfügig',
        displayShort: 'GF',
        displayTooltip: 'Geringfügig 7h / Woche',
      },
      {
        id: JobAdsTypeDefinitionEnum.Ausbildung,
        filterIsActive: false,
        filterParameters: '',
        displayColor: '#effead',
        displayLong: 'Ausbildung',
        displayShort: 'AB',
        displayTooltip: 'Ausbildungsstelle',
      },
    ],
  }
];

