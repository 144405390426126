import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig} from '@angular/material/dialog';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JCD_ENVIRONMENT_DATA} from 'jcd-shared-components';
import {environment} from '../environments/environment';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {fakeBackendInterceptor} from './framework/api/fixture/fake-backend.interceptor';
import {FrameworkModule} from './framework/framework.module';
import {LayoutModule} from './framework/layout/layout.module';
import {MaterialModule} from './framework/material.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FrameworkModule,
    HttpClientModule,
    LayoutModule,
    MaterialModule,
  ],
  providers: [
    {
      provide: JCD_ENVIRONMENT_DATA,
      useValue: environment
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline'
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        width: '100%',
        panelClass: ['dialog-primary']
      } as MatDialogConfig,
    },
    environment.fakeApiResponse ? fakeBackendInterceptor : [],
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
