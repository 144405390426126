import {
  SearchTagInterface
} from '../../../../layout/search-tags/search-tags.component';


export enum GenericSearchTagsEnum {
  ApplicationNew = '11e9706c-a3be-4c29-ab47-c86ce2a5347e',
  ApplicationDocuments = '11a866d3-6f9f-4155-8b0a-7e3ae8e56005',
  ApplicationInvited = 'ebf711df-2e1b-42c7-a69b-1a8fa830e9d3',
  ApplicationInterview = '8f3e3cb8-ebdc-4d5f-8666-9e1ebd1d8dc1',
  ApplicationHired = 'a2b55cc0-f38c-4d5f-9a41-b3c7b9bdc73e',
  ApplicationRejected = '6dc95928-f67e-4c45-a56a-b613ec14dea5',
}

export const searchTagsFixture: SearchTagInterface[] = [
  {
    id: GenericSearchTagsEnum.ApplicationNew,
    name: 'neu',
  },
  {
    id: GenericSearchTagsEnum.ApplicationDocuments,
    name: 'unterlagen',
  },
  {
    id: GenericSearchTagsEnum.ApplicationInvited,
    name: 'eingeladen',
  },
  {
    id: GenericSearchTagsEnum.ApplicationInterview,
    name: 'interview',
  },
  {
    id: GenericSearchTagsEnum.ApplicationHired,
    name: 'eingestellt',
  },
  {
    id: GenericSearchTagsEnum.ApplicationRejected,
    name: 'abgelehnt',
  },
];
