// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {JcdEnvironmentInterface} from 'jcd-shared-components';


export const environment: JcdEnvironmentInterface = {
  locationApiBaseUrl: 'http://localhost:8080',
  locationApiPath: '/api/v1/',
  locationSso: 'http://localhost:4300',
  locationApp: 'http://localhost:4200',
  production: false,
  fakeApiResponse: true,
  fakeApiDelayThreshold: 1,
  authConfig: {
    issuer: 'http://host.docker.internal:8381/realms/jcd',
    clientId: 'sso-app',
    responseType: 'code',
    redirectUri: window.location.origin + '/',
    // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    scope: 'openid profile',
    useSilentRefresh: false,
    // silentRefreshTimeout: 5000, // For faster testing
    // timeoutFactor: 0.25, // For faster testing
    sessionChecksEnabled: false,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    requireHttps: false,
    apiUrl: ['http://localhost:8380/api/'],
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
