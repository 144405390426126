import {WidgetInterface, WidgetTypeEnum} from '../../../../../dashboard/widget/widget-interface';


export let widgetsFixture: WidgetInterface[] = [
  // @formatter:off
  {id: 'bf4ce69e-dcef-4ee1-8e5a-d1b32e488c0d', x: 2, y: 8, w: 2, h: 4, type: WidgetTypeEnum.NUMBER, name: 'Illuminati (5)'},
  {id: '0b132116-4b81-4bd3-a2a8-27f976cf1d2f', x: 2, y: 4, w: 2, h: 4, type: WidgetTypeEnum.GAUGE, name: 'Applicants to think about'},
  {id: '41deeb98-aed4-4017-b4bc-a6e8bcc9b352', x: 0, y: 4, w: 2, h: 4, type: WidgetTypeEnum.GAUGE, name: 'JobAds to think about'},
  {id: 'cf9da6df-ac69-4906-a9c3-332756a916f8', x: 2, y: 0, w: 2, h: 4, type: WidgetTypeEnum.GAUGE, name: 'Workload last week'},
  {id: 'd065cc4b-c574-437f-8070-858a334cade3', x: 4, y: 0, w: 8, h: 6, type: WidgetTypeEnum.GRAPH, name: 'CTR JobAds'},
  {id: '05c67250-dcd0-44eb-95e5-6a213bd4331d', x: 0, y: 8, w: 2, h: 4, type: WidgetTypeEnum.NUMBER, name: 'An eXtreme incredible long meaningless widget title'},
  {id: '42ace496-c6d8-4613-9604-7d141172462b', x: 0, y: 0, w: 2, h: 4, type: WidgetTypeEnum.GAUGE, name: 'Workload this week'},
  {id: '82758864-4440-4bb7-bbdd-21bf46792f12', x: 4, y: 6, w: 8, h: 6, type: WidgetTypeEnum.GRAPH, name: 'Hours per workday'},
  // @formatter:on
];
