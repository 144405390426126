import {Injectable} from '@angular/core';
import {
  JcdApiErrorResponse,
  JcdApiService,
  JcdApiSuccessResponse,
  JcdErrorHandlerService,
  JcdGenericDeleteResponseInterface,
  JcdGenericErrorResponseInterface
} from 'jcd-shared-components';
import {WidgetInterface} from '../../dashboard/widget/widget-interface';
import {UserWidgetCreateRequestInterface} from './api-request.interface';
import {
  UserEmailGetAllResponseInterface,
  UserNotificationGetAllResponseInterface,
  UserProfileGetResponseInterface,
  UserWidgetCreateResponseInterface,
  UserWidgetGetAllResponseInterface,
  UserWidgetReplaceResponseInterface,
} from './api-response.interface';


@Injectable({
  providedIn: 'root',
})
export class ProfileApiService extends JcdApiService {

  public userProfileGet(
    successCallback: (response: JcdApiSuccessResponse<UserProfileGetResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'user/profile',
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback,
    );
  }

  public userEmailGetAll(
    queryParameters: {
      filter?: 'unread',
      limit?: number
    },
    successCallback: (response: JcdApiSuccessResponse<UserEmailGetAllResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'user/emails' + this.buildQueryParameterString(queryParameters),
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback,
    );
  }

  public userNotificationGetAll(
    queryParameters: {
      filter?: 'unread',
      limit?: number
    },
    successCallback: (response: JcdApiSuccessResponse<UserNotificationGetAllResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void,
  ): void {
    this.call(
      'user/notifications' + this.buildQueryParameterString(queryParameters),
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback,
    );
  }

  public userWidgetCreate(
    data: UserWidgetCreateRequestInterface,
    successCallback: (apiResponse: JcdApiSuccessResponse<UserWidgetCreateResponseInterface>) => void,
    errorCallback?: (apiResponse: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void
  ): void {
    this.call(
      'user/widgets',
      this.methods.create,
      data,
      successCallback,
      errorCallback,
      completeCallback
    );
  }

  public userWidgetGetAll(
    successCallback: (response: JcdApiSuccessResponse<UserWidgetGetAllResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void
  ): void {
    this.call(
      'user/widgets',
      this.methods.get,
      {},
      successCallback,
      errorCallback,
      completeCallback
    );
  }

  public userWidgetReplace(
    idWidget: string,
    data: WidgetInterface,
    successCallback: (apiResponse: JcdApiSuccessResponse<UserWidgetReplaceResponseInterface>) => void,
    errorCallback?: (apiResponse: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void
  ): void {
    this.call(
      'user/widgets/' + idWidget,
      this.methods.replace,
      data,
      successCallback,
      errorCallback,
      completeCallback
    );
  }

  public userWidgetDelete(
    idWidget: string,
    successCallback: (response: JcdApiSuccessResponse<JcdGenericDeleteResponseInterface>) => void,
    errorCallback?: (response: JcdApiErrorResponse<JcdGenericErrorResponseInterface>, errorHandler: JcdErrorHandlerService) => void,
    completeCallback?: () => void
  ): void {
    this.call(
      'user/widgets/' + idWidget,
      this.methods.delete,
      {},
      successCallback,
      errorCallback,
      completeCallback
    );
  }
}
