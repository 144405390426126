import {OrganisationLevelInterface} from '../organisation/entities';


export interface UserGroupInterface {
  id: string;
  name: string;
  /**
   * This is the ranking order. A lower number-value means the group is higher
   * in the hierarchy. If a user is in a group with a value of 2, he can not
   * access other groups with the same number-value of 2 or lower, e.g. 1. He
   * can only access the group he is in with a number-value of 2 and all groups
   * with a higher number-value (lower in hierarchy).
   *
   * Note: The server *always* sends groups with a `sort` starting by 1. Even
   * if the group itself is way lower in the hierarchy and has a sort value of
   * 10 in the database. This is (hopefully) easier for the user to understand.
   */
  sort: number;
  /**
   * Groups a user creates are always editable, but groups like 'Admins' aren't,
   * to prevent the customer to exclude themselves from their own organisation.
   */
  readonly isEditable: boolean;
}


export interface OrganisationLevelTreeInterface extends OrganisationLevelInterface {
  children: Array<OrganisationLevelTreeInterface>;
}


/**
 * This interface is only used by the angular flat tree data source and
 * implements the required properties of the flat tree.
 */
export interface OrganisationLevelFlatInterface extends OrganisationLevelInterface {
  expandable: boolean;
  level: number;
}


export interface UserPermissionInterface {
  groupId: string;
  organisationLevelId: string;
  userId: string;
}


export type UserGroupPermissionInterface = {
  [key in PermissionEnum]?: boolean;
}

export enum PermissionEnum {

  application_attachments = 'application_attachments',
  application_communication = 'application_communication',
  application_create = 'application_create',
  application_delete = 'application_delete',
  application_editMetadata = 'application_editMetadata',
  application_editStatus = 'application_editStatus',
  application_read = 'application_read',

  applyForm_create = 'applyForm_create',
  applyForm_delete = 'applyForm_delete',
  applyForm_edit = 'applyForm_edit',

  userGroup_create = 'userGroup_create',
  userGroup_delete = 'userGroup_delete',
  userGroup_edit = 'userGroup_edit',

  jobAd_create = 'jobAd_create',
  jobAd_edit = 'jobAd_edit',
  jobAd_delete = 'jobAd_delete',

  process_create = 'process_create',
  process_delete = 'process_delete',
  process_edit = 'process_edit',

  user_invite = 'user_invite',
  user_editPermission = 'user_editPermission',
  user_delete = 'user_delete',

  reports_read = 'reports_read',
}
